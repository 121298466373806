import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../../styletheme/logos/SavingPalzLogo.png';

import '../../../styletheme/public/css/bootstrap.min.css';
import '../../../styletheme/public/css/flaticon.css';
import '../../../styletheme/public/css/menu.css';

const NavBar = ({}) => {
  const [portalUrl, setPortalUrl] = useState('https://app.savingpalz.com/');
  const [signUpUrl, setSignUpUrl] = useState(
    'https://app.savingpalz.com/signup'
  );
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      bg='light'
      variant='light'
      style={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
      <Navbar.Brand as={Link} to='/' style={{ paddingLeft: '10px' }}>
        <img
          src={logo}
          width='70'
          height='70'
          className='d-inline-block align-top'
          alt='Logo'
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls='responsive-navbar-nav'
        style={{ paddingRight: '10px' }}
      />
      <Navbar.Collapse
        id='responsive-navbar-nav'
        className='justify-content-center'
      >
        <Nav>
          <Nav.Link as={Link} to='/'>
            Home
          </Nav.Link>

          <Nav.Link as={Link} to='/about'>
            About Us
          </Nav.Link>
          <Nav.Link as={Link} to='/blogs'>
            Blogs
          </Nav.Link>
          <Nav.Link as={Link} to='/tutorials'>
            Tutorials
          </Nav.Link>
          <Nav.Link as={Link} to='/pricing'>
            Pricing
          </Nav.Link>
          <Nav.Link as={Link} to='/download'>
            Download
          </Nav.Link>
          <Nav.Link as={Link} to='/contactus'>
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse
        id='responsive-navbar-nav'
        className='justify-content-end'
      >
        <a
          href={portalUrl}
          rel='noopener noreferrer'
          target='_blank'
          className='btn btn-outline-secondary tra-blue-hover last-link'
          style={{
            color: 'black', // Change the color of the "Sign In" button
            marginRight: '10px', // Add space to the right of the "Sign In" button
          }}
        >
          Sign In
        </a>

        <a
          href={signUpUrl}
          rel='noopener noreferrer'
          target='_blank'
          className='btn btn-violet-red tra-green-hover last-link'
        >
          Sign Up
        </a>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
