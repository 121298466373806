import React, { useState } from 'react';

const PricingTableComparisonSection = () => {
  const [tableData, setTableData] = useState([
    {
      feature: 'Budget Management',
      free: true,
      monthlyPro: true,
      annualPro: true,
    },
    {
      feature: 'Expense Management',
      free: true,
      monthlyPro: true,
      annualPro: true,
    },
    {
      feature: 'Budget Goal Setting',
      free: false,
      monthlyPro: true,
      annualPro: true,
    },
    {
      feature: 'Collaborative Budgeting with Palz',
      free: false,
      monthlyPro: true,
      annualPro: true,
    },
  ]);

  return (
    <div className='pricing-compare pc-40'>
      <div className='row'>
        <div className='col'>
          <h3 className='h3-sm text-center'>Compare Pricing Packages</h3>
          <div className='table-responsive'>
            <table className='table text-center'>
              <thead>
                <tr>
                  <th styles='width: 34%;'></th>
                  <th styles='width: 22%;'>Free</th>
                  <th styles='width: 22%;'>Monthly Pro Plan</th>
                  <th styles='width: 22%;'>Annual Pro Plan</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr>
                    <th scope='row' className='text-start'>
                      {row.feature}
                    </th>
                    <td
                      className={
                        row.free
                          ? 'ico-15 green-color'
                          : 'ico-10 disabled-option'
                      }
                    >
                      <span
                        className={
                          row.free ? 'flaticon-check' : 'flaticon-cancel'
                        }
                      ></span>
                    </td>
                    <td
                      className={
                        row.monthlyPro
                          ? 'ico-15 green-color'
                          : 'ico-10 disabled-option'
                      }
                    >
                      <span
                        className={
                          row.monthlyPro ? 'flaticon-check' : 'flaticon-cancel'
                        }
                      ></span>
                    </td>
                    <td
                      className={
                        row.annualPro
                          ? 'ico-15 green-color'
                          : 'ico-10 disabled-option'
                      }
                    >
                      <span
                        className={
                          row.annualPro ? 'flaticon-check' : 'flaticon-cancel'
                        }
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTableComparisonSection;
