import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getLatestBlogs } from '../../services/general/blogs/blogsService';
import { fetchLatestTutorials } from '../../services/general/tutorials/tutorialsService';
import LoadingView from '../common/reusuable/loading/loadingView';

//imports 'images/appstore.png'
import appStoreImg from '../../styletheme/public/images/appstore.png';
import googlePlayImg from '../../styletheme/public/images/googleplay.png';
import appStoreWhiteImg from '../../styletheme/public/images/appstore-white.png';
import googlePlayWhiteImg from '../../styletheme/public/images/googleplay-white.png';

import PortalLink from '../common/reusuable/button/portalLink';
import ClickableImage from '../common/reusuable/images/clickableImage';
import PricingSection from '../common/reusuable/pricing/pricingSection';
import FAQSection from '../common/reusuable/faq/faqSection';

function HomePage() {
  //Need a control that when you press the image it opens a popup with the image in full screen
  //Need Network calls for these methods
  const [isLoading, setIsLoading] = useState(false);
  const [portalUrl, setPortalUrl] = useState('https://app.savingpalz.com/');

  const [headerDetail, setHeaderDetail] = useState({
    companyName: 'SavingPalz: Budget & Expense Tracker',
    title: 'Simplifying Landlording',
    caption: 'Saving is better with Palz.',
    titleSubTitle:
      'SavingPalz is a budgeting and expense tracking app that helps you manage your finances.',
    trialPeriod: '7 Days',
    currentVersion: 1.5,
    appStoreLink:
      'https://apps.apple.com/ca/app/savingpalz-expense-tracker/id6464551318',
    headerPhoneImg:
      'https://firebasestorage.googleapis.com/v0/b/gurenterpromomarketing.appspot.com/o/PromoVideos%2FGurenterDemoMode.gif?alt=media&token=4e7d6e69-5551-4885-9736-4f0cdb798ad3',
    headerBackgroundImg:
      'https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FHomepage%2FProperties_No_Dashboard-Island.webp?alt=media&token=b795d436-2743-4ca0-adfd-2a7d751a22a6',
  });
  const [uniquenessMessage, setUniquenessMessage] = useState({
    title: 'What makes SavingPalz Different',
    message: `Struggling with finances? Let's tackle it together. SavingPalz is not only user-friendly and secure—it's the tool our founders actively use to manage their finances. Built from our personal challenges, we're committed to helping you succeed financially.`,
  });

  const [topThreeFeatures, setTopThreeFeatures] = useState([
    {
      title: 'Budget Management',
      order: 1,
      message:
        'SavingPalz helps you to breakdown your budget into categories and track your spending.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FBudget.png?alt=media&token=a09340fc-4521-422a-a219-7206bfb12817',
    },
    {
      title: 'Expense Management',
      order: 2,
      message:
        'SavingPalz helps you to track your expenses and see where your money is going.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FExpenses.png?alt=media&token=edb2f012-5b1e-41f2-8557-25452a3c7a71',
    },
    {
      title: 'Budget Goal Setting',
      order: 3,
      message:
        'SavingPalz helps you to set budget goals and track your progress towards them.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FBudgetGoals.png?alt=media&token=28bbe0e6-92b5-48f0-a2e4-5e6a233f0a83',
    },
  ]);

  //the learn more are like blogs that talk more about the individual features
  const [mainDetailedFeatures, setMainDetailedFeatures] = useState([
    {
      title: 'Collaborative Budgeting with Palz',
      subTitle: '',
      order: 1,
      message: 'Why budget alone when you can collaborate with a Pal?',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.43.09%E2%80%AFAM.png?alt=media&token=44bc09b9-7480-43ea-b7b9-0121ef298889',
      hasLearnMore: false,
      learnMoreUrl: '/about',
      signUpLink: 'https://app.savingpalz.com/signup',
      mainPoints: [
        {
          message:
            'Join your Budget to increase your saving power and reach your goals faster.',
        },
        {
          message:
            'Share your budget with your Palz to get advice and support.',
        },
        {
          message: 'Track your progress and see how you are doing.',
        },
      ],
    },
    {
      title: 'Budgeting Solo',
      subTitle: 'Individual Budgeting',
      order: 2,
      message: 'Budgeting alone is easy with SavingPalz as well.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Web%20Portal%20Login%20Register%20Images%2FScreenshot%202024-05-07%20at%203.42.12%E2%80%AFAM.png?alt=media&token=eeabdaa9-56cc-488c-97f2-86d2d84d6ac6',
      hasLearnMore: true,
      learnMoreUrl: '/download',
      signUpLink: 'https://app.savingpalz.com/signup',
      mainPoints: [
        {
          message: 'Track your spending and see where your money is going.',
        },
        {
          message: 'Compare your spending against last period.',
        },
        {
          message: 'Ensure you are not going over budget.',
        },
      ],
    },

    {
      title: 'Expense Calendar',
      subTitle: '',
      order: 3,
      message:
        'Visually track your re-occurring expenses with our Expense Calendar.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FMiniCalendar.png?alt=media&token=7166b582-4833-4101-8d78-a2ac5f1d2429',
      hasLearnMore: true,
      learnMoreUrl: '/contactus',
      signUpLink: 'https://app.savingpalz.com/signup',
      mainPoints: [
        {
          message: 'See your expenses in a visual format.',
        },
        {
          message: 'Track your re-occurring expenses.',
        },
        {
          message: 'Ensure you are not missing any payments.',
        },
      ],
    },
    {
      title: 'Budget Goals',
      subTitle: 'All your goals in one place',
      order: 4,
      message: 'Set your budget goals and visually track your progress.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FVisualBudgetGoalTracking.png?alt=media&token=65046f84-b1e0-4f4f-aae5-6b36a2dd24a9',
      hasLearnMore: true,
      learnMoreUrl: '/contactus',
      signUpLink: 'https://app.savingpalz.com/signup',
      mainPoints: [
        {
          title: 'Budget Goal Contribution',
          message: 'See how much you are contributing to your goals.',
        },
        {
          title: 'Filtering Goals',
          message:
            'Filter your goals by name or progress to see what goals are near completion.',
        },
      ],
    },
    {
      title: 'Funding your Goals',
      subTitle: 'Funding',
      order: 5,
      message: 'Easily fund your goals with our Funding feature.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FFundingBudgetGoal.png?alt=media&token=e902df84-7fb5-42dd-a287-dade9ffa7cd3',
      hasLearnMore: true,
      learnMoreUrl: '/contactus',
      signUpLink: 'https://app.savingpalz.com/signup',
      mainPoints: [
        {
          title: 'Funding',
          message: 'You can add funds to your goal, one step at a time.',
        },
        {
          title: 'Modify your Goal',
          message:
            'Is your goal too ambitious? Do not worry; you can adjust it to meet your new needs.',
        },
      ],
    },
  ]);

  const [demoDetail, setDemoDetail] = useState({
    title: 'See SavingPalz in Action',
    subTitle: 'SavingPalz Demo',
    message:
      'Watch our demo to see how SavingPalz can help you manage your finances.',
    videoUrl: '5JvygoSZFKU',
  });

  const [weAreDifferent, setWeAreDifferent] = useState({
    title: 'We’re Better. Here’s Why…',
    message:
      'We are committed to helping you succeed financially. We are always open to feedback from our members. Why? Well, because it will not just make SavingPalz a better product for you but for the community and us as well.',
    reasons: [
      {
        title: 'No Advertisements or Data Selling Ever',
        message:
          'We do not sell your data or show you advertisements. Advertisements are distracting and clutter your experience. We use our own product, hate ads, and value privacy. As a result, we want to build the best financial product for you and keep innovating to improve your experience.',
        icon: 'flaticon-shield-2',
      },
      {
        title: 'We are where you are',
        message:
          'We are available on various platforms, including the web and iOS. Android is coming soon. We are always looking to expand our reach to make it easier for you to access your finances.',
        icon: 'flaticon-worldwide',
      },
      {
        title: 'We are always here to help',
        message:
          'We are always here to help you with any questions or concerns you may have. We are committed to providing you with the best customer service possible.',
        icon: 'flaticon-avatar',
      },
      {
        title: 'We are always innovating',
        message:
          'We are always looking to improve SavingPalz and add new features to help you manage your finances better.',
        icon: 'flaticon-idea',
      },
    ],
  });

  const [pricingDetail, setPricingDetail] = useState({
    title: 'Simple Pricing Structure',
    message:
      'Our pricing is simple and easy to understand. We offer a 7-day free trial, after the trial, you can choose to continue with our free plan or upgrade to our pro plan.',
    pricingPlans: [
      {
        title: 'Free Plan',
        message:
          'Our free plan includes basic features to help you manage your finances. These include budgeting and expense tracking.',
        price: 'Free',
        isFree: true,
        priceInDollars: 0,
        priceInCents: 0,
        planPeriod: 'forever',
      },
      {
        title: 'Monthly Pro Plan',
        message:
          'Our pro plan includes what is in the free plan plus budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
        price: '$9.99/month',
        isFree: false,
        priceInDollars: 9,
        priceInCents: 99,
        planPeriod: 'month',
      },
      {
        title: 'Annual Pro Plan',
        message:
          'Our pro plan includes what is in the free plan plus budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
        price: '$119.88/year',
        isBestValue: true,
        isFree: false,
        priceInDollars: 119,
        priceInCents: 88,
        planPeriod: 'year',
      },
    ],
  });

  const [frequentlyAskedQuestion, setFrequentlyAskedQuestion] = useState({
    title: 'Everything you need to know before getting started',
    subTitle: 'Frequently Asked Questions',
    questions: [
      {
        question: 'What is SavingPalz?',
        answer:
          'SavingPalz is a budgeting and expense tracking app that helps you manage your finances.',
      },
      {
        question: 'How much does SavingPalz cost?',
        answer:
          'SavingPalz offers a free plan and a pro plan. The free plan includes basic features to help you manage your finances. The pro plan is $9.99 per month and includes additional features such as budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
      },
      {
        question: 'Is SavingPalz secure?',
        answer:
          'Yes, SavingPalz takes security seriously. All data is encrypted and stored securely.',
      },
      {
        question: 'How long is the free trial?',
        answer:
          'The free trial is 7 days. After the trial, you can choose to continue with our free plan or upgrade to our pro plan.',
      },
      {
        question: 'What platforms is SavingPalz available on?',
        answer:
          'SavingPalz is available on the web and iOS. An Android version is coming soon.',
      },
      {
        question: 'Can I cancel my subscription at any time?',
        answer:
          'Yes, you can cancel your subscription at any time. Your subscription will remain active until the end of the billing period.',
      },
    ],
  });

  const [mobileAppDetail, setMobileAppDetail] = useState({
    title: 'Download the SavingPalz App',
    subTitle: 'Available on iOS. Coming soon to Android.',
    message: 'Start managing your finances today with SavingPalz.',
    appStoreLink:
      'https://apps.apple.com/ca/app/savingpalz-expense-tracker/id6464551318',
    iosScreenshot:
      'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FMobile%20App%2FDashboardLightMode.png?alt=media&token=662eb87a-37e9-4de5-8a08-e45eac6294de',
    hasAndroid: false,
    googlePlayLink: '',
  });

  const [homePageLatestArticles, setHomePageLatestArticles] = useState([
    {
      categoryName: '',
      blogId: '',
      blogTitle: '',
      blogDateTime: '',
      blogOverview: '',
      blogPromoImg: '', //web optimised
      blogSections: [
        {
          //todo soon
        },
      ],
      numberOfComments: 0,
    },
  ]);

  const [Tutorials, setTutorials] = useState([
    {
      tutorialId: '',
      tutorialTitle: '',
      videoUploadDate: '',
      youTubeVideoCode: '',
      tutorialOverview: '',
      tutorialPromoImage: '',
      deviceSpecificImages: [
        {
          deviceType: '',
          deviceImageUrl: '',
        },
      ],
      tutorialTags: [
        {
          tagId: '',
          tagName: '',
          tagDescription: '',
        },
      ],
      commentCount: 0,
      authorName: '',
      authorProfile: {
        authorId: '',
        authorName: '',
        authorProfileImgUrl: '',
        authorProfileOverview: '',
      },
      tutorialCategories: [
        {
          categoryId: '',
          categoryName: '',
          categoryDescription: '',
        },
      ],
      tutorialSections: [
        {
          sectionId: '',
          sectionType: '',
          sectionTitle: '',
          sectionPicUrl: '',
          deviceSpecificImages: [
            {
              deviceType: '',
              deviceImageUrl: '',
            },
          ],
          sectionSectionExplanation: '',
          mathEquationLogic: '',
          sourceCodeSnippets: [
            {
              sourceCodeTitle: '',
              sourceCodeExplanation: '',
              sourceCodeDetail: '',
              languageId: '',
              languageName: '',
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: '',
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: '',
              authorProfileUrl: '',
              authorProfileOverview: '',
              authorTitle: '',
            },
          ],
          referenceDetail: '',
        },
      ],
      recordCreatedDate: '',
      isDraft: false,
      tutorialPlatform: '',
      locationInApp: '',
    },
  ]);

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);
    setIsLoading(true);
    findLatestBlogs();
    findLatestTutorials();
  }, []);

  const findLatestBlogs = async () => {
    setIsLoading(true);

    try {
      const { data: latestBlogs } = await getLatestBlogs(3);
      if (latestBlogs.length > 0) {
        setHomePageLatestArticles(latestBlogs);
      } else {
        // Handle the case when the array is empty
      }
    } catch (error) {
      //only in dev
      //console.log(error);
      // Handle the error
    }

    setIsLoading(false);
  };

  const findLatestTutorials = async () => {
    setIsLoading(true);

    try {
      const { data: Tutorials } = await fetchLatestTutorials(3);
      if (Tutorials.length > 0) {
        setTutorials(Tutorials);
      } else {
        // Handle the case when the array is empty
      }
    } catch (error) {
      //only in dev
      //console.log(error);
      // Handle the error
    }

    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>SavingPalz Home Page</title>
        <meta
          name='description'
          content='This is the home page of SavingPalz'
        />
        <meta
          name='keywords'
          content='SavingPalz, Budgeting, Expense Tracking, Budget Goal Tracking, Collaborative Budgeting'
        />

        <meta property='og:title' content='SavingPalz Home Page' />
        <meta
          property='og:description'
          content='This is the home page of SavingPalz'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://savingpalz.com/' />
      </Helmet>
      {/* PRELOADER SPINNER Make it into sub components after
		============================================= */}
      {isLoading && <LoadingView />}
      {/* HERO-14
			============================================= */}
      <section id='hero-14' className='bg-fixed hero-section division'>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            {/* HERO TEXT */}
            <div className='col-sm-9 col-md-7 col-lg-5'>
              <div className='hero-14-txt white-color wow fadeInRight'>
                {/* Section ID */}
                <span className='section-id txt-upcase'>
                  {headerDetail.caption}
                </span>
                {/* Title */}
                <h2 className='h2-xl'>{headerDetail.companyName}</h2>
                {/* Text */}
                <p className='p-xl'>{headerDetail.titleSubTitle}</p>
                {/* STORE BADGES */}
                <div className='stores-badge'>
                  {/* AppStore */}
                  <a
                    href={headerDetail.appStoreLink}
                    target='_blank'
                    className='store'
                  >
                    <img
                      className='appstore'
                      src={appStoreImg}
                      alt='appstore-badge'
                    />
                  </a>

                  {/* Google Play */}
                  <a href='#' className='store' hidden>
                    <img
                      className='googleplay'
                      src={googlePlayImg}
                      alt='googleplay-badge'
                    />
                  </a>

                  <PortalLink portalUrl={portalUrl} />
                  {/* Aamazon Market 
									<a href="#" className="store">
										<img className="amazon" src="images/amazon.png" alt="amazon-badge" />
									</a>  */}

                  {/* Mac AppStore 
									<a href="#" className="store">
										<img className="mac-appstore" src="images/macstore.png" alt="macstore-badge" />
									</a> */}

                  {/* Microsoft Store  
									<a href="#" className="store">
										<img className="microsoft" src="images/microsoft.png" alt="microsoft-badge" />
									</a> */}
                </div>{' '}
                {/* END STORE BADGES */}
              </div>
            </div>{' '}
            {/* END HERO TEXT */}
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END HERO-14 */}
      {/* FEATURES-8
			============================================= */}
      <section
        id='features-8'
        className='bg-snow wide-60 features-section division'
      >
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-xl-8'>
              <div className='section-title title-01 mb-70'>
                {/* Title */}
                <h2 className='h2-md'>{uniquenessMessage.title}</h2>

                {/* Text */}
                <p className='p-xl'>{uniquenessMessage.message}</p>
              </div>
            </div>
          </div>
          {/* FEATURES-8 WRAPPER */}
          <div className='fbox-8-wrapper text-center'>
            <div className='row row-cols-1 row-cols-md-3'>
              {topThreeFeatures.map((feature, index) => (
                <div className='col' key={index}>
                  <div className='fbox-8 mb-40 wow fadeInUp'>
                    {/* Image */}
                    <div className='fbox-img bg-whitesmoke-gradient'>
                      {/*
                      <img
                        className='img-fluid'
                        src={feature.imageUrl}
                        alt={feature.title}
                      />
              */}

                      <ClickableImage
                        imageUrl={feature.imageUrl}
                        altText={feature.title}
                      />
                    </div>

                    {/* Title */}
                    <h5 className='h5-md'>{feature.title}</h5>

                    {/* Text */}
                    <p className='p-lg'>{feature.message}</p>
                  </div>
                </div>
              ))}
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* END FEATURES-8 WRAPPER */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END FEATURES-8 */}
      {/* CONTENT-6
			============================================= */}
      {/*Feature 1 : show the first order */}
      {mainDetailedFeatures &&
        mainDetailedFeatures.length > 0 &&
        mainDetailedFeatures
          .filter((feature) => feature.order === 1)
          .map((feature, index) => (
            <section
              key={index}
              id='content-6'
              className='content-6 wide-60 content-section division'
            >
              <div className='container'>
                <div className='row d-flex align-items-center'>
                  {/* TEXT BLOCK */}
                  <div className='col-md-6 col-lg-5'>
                    <div className='txt-block left-column wow fadeInRight'>
                      {/* TEXT BOX */}
                      <div className='txt-box mb-20'>
                        {/* Title */}
                        <h5 className='h5-lg'>{feature.title}</h5>

                        {/* Text */}
                        <p className='p-lg'>{feature.message}</p>

                        {/* List */}
                        <ul className='simple-list'>
                          {feature.mainPoints.map((point, index) => (
                            <li className='list-item' key={index}>
                              <p className='p-lg'>{point.message}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* END TEXT BOX */}
                    </div>
                  </div>

                  {/* IMAGE BLOCK */}
                  <div className='col-md-6 col-lg-7'>
                    <div className='img-block right-column wow fadeInLeft'>
                      {/* <img
                        className='img-fluid'
                        src={feature.imageUrl}
                        alt='content-image'
                        /> */}

                      <ClickableImage imageUrl={feature.imageUrl} />
                    </div>
                  </div>
                </div>{' '}
                {/* End row */}
              </div>{' '}
              {/* End container */}
            </section>
          ))}
      {/* END CONTENT-6 */}
      {/* DIVIDER LINE */}
      <hr className='divider' />
      {/* FEATURES-4
			============================================= */}
      <section id='features-4' className='wide-50 features-section division'>
        {weAreDifferent && (
          <div className='container'>
            {/* SECTION TITLE */}
            <div className='row justify-content-center'>
              <div className='col-lg-10 col-xl-8'>
                <div className='section-title title-01 mb-70'>
                  {/* Title */}
                  <h2 className='h2-md'>{weAreDifferent.title}</h2>

                  {/* Text */}
                  <p className='p-xl'>{weAreDifferent.message}</p>
                </div>
              </div>
            </div>
            {/* FEATURES-4 WRAPPER */}
            <div className='fbox-4-wrapper fbox-4-wide'>
              <div className='row row-cols-1 row-cols-md-2'>
                {/* FEATURE BOX #1 */}

                {weAreDifferent.reasons.map((reason, index) => (
                  <div className='col' key={index}>
                    <div className='fbox-4 pr-25 mb-40 wow fadeInUp'>
                      {/* Icon */}
                      <div className='fbox-ico'>
                        <div className='ico-65'>
                          <span className={reason.icon}></span>
                        </div>
                      </div>

                      {/* Text */}
                      <div className='fbox-txt'>
                        {/* Title */}
                        <h5 className='h5-md'>{reason.title}</h5>

                        {/* Text */}
                        <p className='p-lg'>{reason.message}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>{' '}
            {/* END FEATURES-4 WRAPPER */}
          </div>
        )}

        {/* End container */}
      </section>{' '}
      {/* END FEATURES-4 */}
      {/* CONTENT-3
			============================================= */}
      <section
        id='content-3'
        className='content-3 bg-snow wide-60 content-section division'
      >
        <div className='container'>
          {/* TOP ROW */}
          {mainDetailedFeatures &&
            mainDetailedFeatures.length > 0 &&
            mainDetailedFeatures
              .filter((feature) => feature.order === 2)
              .map((feature, index) => (
                <div className='top-row pb-50' key={index}>
                  <div className='row d-flex align-items-center'>
                    {/* IMAGE BLOCK */}
                    <div className='col-md-5 col-lg-6'>
                      <div className='img-block left-column wow fadeInRight'>
                        {/*  <img
                          className='img-fluid'
                          src={feature.imageUrl}
                          alt='content-image'
              />*/}
                        <ClickableImage
                          imageUrl={feature.imageUrl}
                          altText={feature.title}
                        />
                      </div>
                    </div>
                    {/* TEXT BLOCK */}
                    <div className='col-md-7 col-lg-6'>
                      <div className='txt-block right-column wow fadeInLeft'>
                        {/* Section ID */}
                        <span className='section-id txt-upcase'>
                          {feature.subTitle}
                        </span>

                        {/* Title */}
                        <h2 className='h2-xs'>{feature.title}</h2>
                        <p className='p-lg'>{feature.message}</p>
                        {/* List */}
                        <ul className='simple-list'>
                          {feature.mainPoints.map((point, index) => (
                            <li className='list-item' key={index}>
                              <p className='p-lg'>{point.message}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>{' '}
                    {/* END TEXT BLOCK */}
                  </div>
                </div>
              ))}
          {/* END TOP ROW */}
          {/* BOTTOM ROW */}
          <div className='bottom-row'>
            {mainDetailedFeatures &&
              mainDetailedFeatures.length > 0 &&
              mainDetailedFeatures
                .filter((feature) => feature.order === 3)
                .map((feature, index) => (
                  <div className='row d-flex align-items-center' key={index}>
                    {/* TEXT BLOCK */}
                    <div className='col-md-7 col-lg-6 order-last order-md-2'>
                      <div className='txt-block left-column wow fadeInRight'>
                        {/* TEXT BOX */}
                        <div className='txt-box mb-20'>
                          {/* Title */}
                          <h5 className='h5-lg'>{feature.title}</h5>

                          {/* Text */}
                          <p className='p-lg'>{feature.message}</p>
                        </div>
                        {/* TEXT BOX */}
                        <div className='txt-box'>
                          {/* Title */}

                          {/* List */}
                          <ul className='simple-list'>
                            {feature.mainPoints.map((point, index) => (
                              <li className='list-item' key={index}>
                                <p className='p-lg'>{point.message}</p>
                              </li>
                            ))}
                          </ul>
                        </div>{' '}
                        {/* END TEXT BOX */}
                      </div>
                    </div>{' '}
                    {/* END TEXT BLOCK */}
                    {/* IMAGE BLOCK */}
                    <div className='col-md-5 col-lg-6 order-first order-md-2'>
                      <div className='img-block right-column wow fadeInLeft'>
                        {/*<img
                          className='img-fluid'
                          src={feature.imageUrl}
                          alt='content-image'
                        />*/}

                        <ClickableImage
                          imageUrl={feature.imageUrl}
                          altText={feature.title}
                        />
                      </div>
                    </div>
                  </div>
                ))}
          </div>{' '}
          {/* END BOTTOM ROW */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END CONTENT-3 */}
      {/* CONTENT-2
			============================================= */}
      <section
        id='content-2'
        className='content-2 wide-60 content-section division'
      >
        {mainDetailedFeatures &&
          mainDetailedFeatures.length > 0 &&
          mainDetailedFeatures
            .filter((feature) => feature.order === 4)
            .map((feature, index) => (
              <div className='container' key={index}>
                {/* SECTION TITLE */}
                <div className='row justify-content-center'>
                  <div className='col-lg-10 col-xl-8'>
                    <div className='section-title title-01 mb-70'>
                      {/* Title */}
                      <h2 className='h2-md'>{feature.title}</h2>

                      {/* Text */}
                      <p className='p-xl'>{feature.message}</p>
                    </div>
                  </div>
                </div>
                <div className='row d-flex align-items-center'>
                  {/* IMAGE BLOCK */}
                  <div className='col-md-5 col-lg-6'>
                    <div className='rel img-block left-column wow fadeInRight'>
                      {/* <img
                        className='img-fluid'
                        src={feature.imageUrl}
                        alt='feature image'
                      />*/}

                      <ClickableImage
                        imageUrl={feature.imageUrl}
                        altText={feature.title}
                      />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className='col-md-7 col-lg-6'>
                    <div className='txt-block right-column wow fadeInLeft'>
                      {/* TEXT BOX */}
                      {feature.mainPoints.map((point, index) => (
                        <div className='txt-box mb-20' key={index}>
                          {/* Title */}
                          <h5 className='h5-lg'>{point.title}</h5>

                          {/* Text */}
                          <p className='p-lg'>{point.message}</p>
                        </div>
                      ))}
                      {/* END TEXT BOX */}
                    </div>
                  </div>{' '}
                  {/* END TEXT BLOCK */}
                </div>{' '}
                {/* End row */}
              </div>
            ))}
      </section>{' '}
      {/* END CONTENT-2 */}
      {/* DIVIDER LINE */}
      <hr className='divider' hidden />
      {/* STATISTIC-2 In future
			============================================= */}
      <section
        id='statistic-2'
        className='wide-100 statistic-section division'
        hidden
      >
        <div className='container'>
          <div className='row d-flex align-items-center'>
            {/* TEXT BLOCK */}
            <div className='col-lg-6'>
              <div className='txt-block right-column wow fadeInLeft'>
                <h3 className='h3-xs'>
                  More than 65,000 users around the world are already using
                  SavingPalz actively
                </h3>
              </div>
            </div>

            {/* STATISTIC BLOCK #1 */}
            <div className='col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1'>
              <div className='statistic-block wow fadeInUp'>
                {/* Text */}
                <h2 className='h2-title-xs statistic-number'>
                  <span className='count-element'>65</span>K
                </h2>
                <p className='p-lg mt-20'>
                  Active downloads <br /> from the community
                </p>
              </div>
            </div>

            {/* STATISTIC BLOCK #2 */}
            <div className='col-sm-5 col-md-4 col-lg-2'>
              <div className='statistic-block wow fadeInUp'>
                {/* Text */}
                <h2 className='h2-title-xs statistic-number'>
                  <span className='count-element'>4</span>.
                  <span className='count-element'>86</span>
                </h2>

                {/* Rating */}
                <div className='txt-block-rating ico-15 yellow-color'>
                  <span className='flaticon-star-1'></span>
                  <span className='flaticon-star-1'></span>
                  <span className='flaticon-star-1'></span>
                  <span className='flaticon-star-1'></span>
                  <span className='flaticon-star-half-empty'></span>
                </div>

                <p className='p-lg txt-400'>8,376 Rating</p>
              </div>
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END STATISTIC-2 */}
      {/* DIVIDER LINE */}
      <hr className='divider' />
      {/* CONTENT-7
			============================================= */}
      <section
        id='content-7'
        className='content-7 wide-60 content-section division'
      >
        {mainDetailedFeatures &&
          mainDetailedFeatures.length > 0 &&
          mainDetailedFeatures
            .filter((feature) => feature.order === 5)
            .map((feature, index) => (
              <div className='container' key={index}>
                <div className='row d-flex align-items-center'>
                  {/* TEXT BLOCK */}
                  <div className='col-md-6 order-last order-md-2'>
                    <div className='txt-block left-column wow fadeInLeft'>
                      {/* Section ID */}
                      <span className='section-id txt-upcase'>
                        {feature.subTitle}
                      </span>
                      {/* Title */}
                      <h2 className='h2-xs'>{feature.title}</h2>
                      {/* Text */}
                      <p className='p-lg'>{feature.message}</p>
                      {/* TEXT BOX */}
                      <div className='txt-box'>
                        {/* Title */}

                        {/* List */}
                        <ul className='simple-list'>
                          {feature.mainPoints.map((point, index) => (
                            <li className='list-item'>
                              <p className='p-lg'>{point.message}</p>
                            </li>
                          ))}
                        </ul>
                      </div>{' '}
                      {/* END TEXT BOX */}
                    </div>
                  </div>{' '}
                  {/* END TEXT BLOCK */}
                  {/* IMAGE BLOCK */}
                  <div className='col-md-6 order-first order-md-2'>
                    <div className='img-block wow fadeInRight'>
                      {/*<img
                        className='img-fluid'
                        src={feature.imageUrl}
                        alt='content-image'
                      />*/}

                      <ClickableImage
                        imageUrl={feature.imageUrl}
                        altText={feature.title}
                      />
                    </div>
                  </div>
                </div>{' '}
                {/* End row */}
              </div>
            ))}
        {/* End container */}
      </section>{' '}
      {/* END CONTENT-7 */}
      {/* CONTENT-10
			============================================= */}
      <section
        id='content-10'
        className='content-10 pb-100 content-section division'
      >
        {demoDetail && (
          <div className='container'>
            {/* SECTION TITLE */}
            <div className='row justify-content-center'>
              <div className='col-md-10 col-lg-8'>
                <div className='section-title title-02 mb-60'>
                  {/* Section ID */}
                  <span className='section-id txt-upcase'>
                    {demoDetail.subTitle}
                  </span>

                  {/* Title */}
                  <h2 className='h2-xs'>{demoDetail.title}</h2>

                  {/* Text */}
                  <p className='p-xl'>{demoDetail.message}</p>
                </div>
              </div>
            </div>

            {/* IMAGE BLOCK */}
            <div className='row'>
              <div className='col'>
                <div className='img-block text-center wow fadeInUp'>
                  <iframe
                    className='img-fluid'
                    src={`https://www.youtube.com/embed/${demoDetail.videoUrl}`} // replace {VIDEO_ID} with your YouTube video's ID
                    title='YouTube video'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    style={{ width: '100%', height: '500px' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* End container */}
      </section>{' '}
      {/* END CONTENT-10 */}
      {/* PRICING-1
			============================================= */}
      <PricingSection pricingDetail={pricingDetail} />
      {/* FAQs-2
			============================================= */}
      <FAQSection frequentlyAskedQuestion={frequentlyAskedQuestion} />
      {/* END FAQs-2 */}
      {/* CONTENT-4
			============================================= */}
      <section id='content-4' className='content-4 pt-80 content-section'>
        <div className='bg-inner bg-04 pb-100 division'>
          {mobileAppDetail && (
            <div className='container'>
              <div className='row d-flex align-items-center'>
                {/* IMAGE BLOCK */}
                <div className='col-md-5 col-lg-6 order-end order-md-2'>
                  <div className='content-4-img left-column wow fadeInRight'>
                    <img
                      className='img-fluid'
                      src={mobileAppDetail.iosScreenshot}
                      style={{ width: '60%', height: 'auto' }}
                      alt='content-image'
                    />
                  </div>
                </div>
                {/* TEXT BLOCK */}
                <div className='col-md-7 col-lg-6 order-first order-md-2'>
                  <div className='txt-block right-column white-color wow fadeInLeft'>
                    {/* Section ID */}
                    <span className='section-id txt-upcase'>
                      {mobileAppDetail.subTitle}
                    </span>
                    {/* Title */}
                    <h2 className='h2-xs'>{mobileAppDetail.title}</h2>
                    {/* Text */}
                    <p className='p-lg'>{mobileAppDetail.message}</p>
                    {/* STORE BADGES */}
                    <div className='stores-badge'>
                      {/* AppStore */}
                      <a
                        href={mobileAppDetail.appStoreLink}
                        target='_blank'
                        className='store'
                      >
                        <img
                          className='appstore'
                          src={appStoreWhiteImg}
                          alt='appstore-badge'
                        />
                      </a>

                      {/* Google Play */}
                      <a
                        hidden
                        href={mobileAppDetail.googlePlayLink}
                        target='_blank'
                        className='store'
                      >
                        <img
                          className='googleplay'
                          src={googlePlayWhiteImg}
                          alt='googleplay-badge'
                        />
                      </a>
                    </div>{' '}
                    {/* END STORE BADGES */}
                  </div>
                </div>{' '}
                {/* END CONTENT TXT */}
              </div>{' '}
              {/* End row */}
            </div>
          )}

          {/* End container */}
        </div>{' '}
        {/* End Inner Background */}
      </section>{' '}
      {/* END CONTENT-4 */}
    </>
  );
}

export default HomePage;
