import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';

import NewsletterForm from '../../../../common/reusuable/newletter/newsletterForm';
import {
  fetchTutorialByTitle,
  getLatestTutorialsAndExclude,
} from '../../../../../services/general/tutorials/tutorialsService';
import LoadingView from '../../../../common/reusuable/loading/loadingView';

//may add the clickable image component here
const TutorialDetail = () => {
  const history = useNavigate();
  const { tutorialTitle } = useParams();
  const [isLoading, setIsLoading] = useState();

  const [socialMediaLink, setSocialMediaLink] = useState({
    facebookSocialMediaLink:
      'https://www.facebook.com/p/SavingPalz-61550599034370/',
    linkedInSocialMediaLink: 'https://www.linkedin.com/company/savingpalz',
    youtubeSocialMediaLink: 'https://www.youtube.com/@SavingPalz',
  });

  //TODO create the tutorials service and call it here
  const [Tutorial, setTutorial] = useState({
    tutorialId: '',
    tutorialTitle: '',
    videoUploadDate: '',
    youTubeVideoCode: '',
    tutorialOverview: '',
    tutorialPromoImage: '',
    deviceSpecificImages: [
      {
        deviceType: '',
        deviceImageUrl: '',
      },
    ],
    tutorialTags: [
      {
        tagId: '',
        tagName: '',
        tagDescription: '',
      },
    ],
    commentCount: 0,
    authorName: '',
    authorProfile: {
      authorId: '',
      authorName: '',
      authorProfileImgUrl: '',
      authorProfileOverview: '',
    },
    tutorialCategories: [
      {
        categoryId: '',
        categoryName: '',
        categoryDescription: '',
      },
    ],
    tutorialSections: [
      {
        sectionId: '',
        sectionType: '',
        sectionTitle: '',
        sectionPicUrl: '',
        deviceSpecificImages: [
          {
            deviceType: '',
            deviceImageUrl: '',
          },
        ],
        sectionSectionExplanation: '',
        mathEquationLogic: '',
        sourceCodeSnippets: [
          {
            sourceCodeTitle: '',
            sourceCodeExplanation: '',
            sourceCodeDetail: '',
            languageId: '',
            languageName: '',
          },
        ],
      },
    ],
    references: [
      {
        referenceTitle: '',
        referenceYear: 0,
        referenceAuthors: [
          {
            authorName: '',
            authorProfileUrl: '',
            authorProfileOverview: '',
            authorTitle: '',
          },
        ],
        referenceDetail: '',
      },
    ],
    recordCreatedDate: '',
    isDraft: false,
    tutorialPlatform: '',
    locationInApp: '',
  });

  //use to populate 3 recent tutorials
  const [Tutorials, setTutorials] = useState([
    {
      tutorialId: '',
      tutorialTitle: '',
      videoUploadDate: '',
      youTubeVideoCode: '',
      tutorialOverview: '',
      tutorialPromoImage: '',
      deviceSpecificImages: [
        {
          deviceType: '',
          deviceImageUrl: '',
        },
      ],
      tutorialTags: [
        {
          tagId: '',
          tagName: '',
          tagDescription: '',
        },
      ],
      commentCount: 0,
      authorName: '',
      authorProfile: {
        authorId: '',
        authorName: '',
        authorProfileImgUrl: '',
        authorProfileOverview: '',
      },
      tutorialCategories: [
        {
          categoryId: '',
          categoryName: '',
          categoryDescription: '',
        },
      ],
      tutorialSections: [
        {
          sectionId: '',
          sectionType: '',
          sectionTitle: '',
          sectionPicUrl: '',
          deviceSpecificImages: [
            {
              deviceType: '',
              deviceImageUrl: '',
            },
          ],
          sectionSectionExplanation: '',
          mathEquationLogic: '',
          sourceCodeSnippets: [
            {
              sourceCodeTitle: '',
              sourceCodeExplanation: '',
              sourceCodeDetail: '',
              languageId: '',
              languageName: '',
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: '',
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: '',
              authorProfileUrl: '',
              authorProfileOverview: '',
              authorTitle: '',
            },
          ],
          referenceDetail: '',
        },
      ],
      recordCreatedDate: '',
      isDraft: false,
      tutorialPlatform: '',
      locationInApp: '',
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getTutorialDetail();
  }, []);

  const getTutorialDetail = async () => {
    if (tutorialTitle !== undefined) {
      setIsLoading(true);
      //console.log("Search by " + tutorialTitle);
      const { data: tutorial } = await fetchTutorialByTitle(tutorialTitle);
      //console.log(tutorial);
      //console.log("Title: " + tutorial.tutorialId);
      if (tutorial !== undefined && tutorial.length !== 0) {
        // console.log("Tutorial Detail");
        // console.log(tutorial);

        setTutorial(tutorial);
        setIsLoading(false);
        const limit = 3;

        const { data: Tutorials } = await getLatestTutorialsAndExclude(
          tutorial.tutorialId,
          limit
        );

        //console.log(Tutorials);

        if (Tutorials !== undefined && Tutorials.length > 0) {
          //toast.info(`Other Tutorials: ${Tutorials.length}`);
          setTutorials(Tutorials);
        }
        setIsLoading(false);
      } else
        toast.warning(
          'No Tutorials Found, Please select Tutorial To see all available.'
        );

      setIsLoading(false);
    } else if (tutorialTitle === undefined) {
      toast.warning(
        'No Tutorials Found, Please select Tutorial To see all available.'
      );

      setIsLoading(false);
    }
  };

  const updateUI = async (value) => {
    setIsLoading(true);

    window.scrollTo(0, 0);

    if (value !== undefined && value !== '') {
      history(`/tutorial/${value.tutorialTitle}`, {
        replace: true,
      });

      const { data: tutorial } = await fetchTutorialByTitle(
        value.tutorialTitle
      );

      if (tutorial !== undefined && tutorial.length !== 0) {
        setTutorial(tutorial);
        setIsLoading(false);
        const { data: Tutorials } = await getLatestTutorialsAndExclude(
          tutorial.tutorialId,
          3
        );
        if (Tutorials !== undefined) setTutorials(Tutorials);
      } else
        toast.warning(
          'No Tutorial Item Found, Please select Tutorial To see all available.'
        );
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* SEO Metadata */}
      <Helmet>
        <title>SavingPalz: {Tutorial.tutorialTitle}</title>
        <meta name='description' content={Tutorial.tutorialOverview} />
        <meta
          name='keywords'
          content={
            Tutorial.tutorialCategories
              ? Tutorial.tutorialCategories
                  .map((category) => category.categoryName)
                  .join(', ')
              : ''
          }
        />
        <meta name='author' content={Tutorial.authorProfile?.authorName} />

        <meta
          property='og:title'
          content={Tutorial.tutorialTitle}
          data-rh='true'
        />
        <meta
          property='og:description'
          content={Tutorial.tutorialOverview}
          data-rh='true'
        />
        <meta
          property='og:image'
          content={Tutorial.tutorialPromoImage}
          data-rh='true'
        />
        <meta
          property='og:url'
          content={`https://savingpalz.com/tutorial/${Tutorial.tutorialTitle}`}
          data-rh='true'
        />
        <meta property='og:type' content='article' data-rh='true' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content={`https://savingpalz.com/tutorial/${Tutorial.tutorialTitle}`}
        />
        <meta
          property='twitter:title'
          content={`SavingPalz: ${Tutorial.tutorialTitle}`}
        />
        <meta
          property='twitter:description'
          content={Tutorial.tutorialOverview}
        />
        <meta property='twitter:image' content={Tutorial.tutorialPromoImage} />
      </Helmet>
      {/* PRELOADER SPINNER */}
      {isLoading && <LoadingView />}
      {/* SINGLE POST */}
      {/* Your single post JSX goes here */}
      <section
        id='single-post'
        className='wide-100 single-post-section division'
      >
        <div className='container'>
          {/* SINGLE POST CONTENT */}
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='single-post-wrapper'>
                {/* SINGLE POST TITLE */}
                <div className='single-post-title'>
                  {/* CATEGORY */}
                  <p className='p-sm post-tag txt-500 txt-upcase'>
                    {Tutorial.tutorialCategories.map((category, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <>&ensp;|&ensp;</>}
                        {category.categoryName}
                      </React.Fragment>
                    ))}
                    &ensp;|&ensp;
                    <span>{Tutorial.tutorialPlatform}</span>
                  </p>
                  {/* TITLE */}
                  <h2 className='h2-md'>{Tutorial.tutorialTitle}</h2>
                  {/* POST DATA */}
                  <div className='post-data clearfix'>
                    {/* Author Avatar */}
                    <div
                      className='post-author-avatar'
                      style={{ display: 'none' }}
                    >
                      <img
                        src={Tutorial.authorProfile?.authorProfileImg}
                        alt='author-avatar'
                        style={{ height: '60px', width: '60px' }}
                      />
                    </div>

                    {/* Author Data */}
                    <div className='post-author'>
                      <h6 className='h6-xl'>
                        {Tutorial.authorProfile?.authorName}
                      </h6>
                      <p className='p-md'>
                        Posted on{' '}
                        <span>
                          {new Date(Tutorial.recordCreatedDate).getDate()}{' '}
                          {new Date(Tutorial.recordCreatedDate).toLocaleString(
                            'default',
                            {
                              month: 'long',
                            }
                          )}{' '}
                          {new Date(Tutorial.recordCreatedDate).getFullYear()}
                        </span>
                      </p>
                    </div>
                  </div>{' '}
                  {/* END POST DATA */}
                </div>{' '}
                {/* END SINGLE POST TITLE */}
                {/* Youtube video here if found start */}
                {Tutorial.youTubeVideoCode &&
                  Tutorial.youTubeVideoCode !== 'N/A' && (
                    <div className='video-preview'>
                      {/* Play Icon */}
                      <Iframe
                        url={
                          'https://www.youtube.com/embed/' +
                          Tutorial.youTubeVideoCode
                        }
                        width='90%'
                        height='585px'
                        id='youTubeVideoCode'
                        display='initial'
                        frameborder='0'
                        allowfullscreen
                        position='relative'
                      />
                    </div>
                  )}
                {/* Youtube video here if found end */}
                {/* BLOG POST TEXT */}
                <div className='single-post-txt'>
                  {/* Text */}
                  <p className='p-lg'>{Tutorial.tutorialOverview}</p>
                </div>{' '}
                {/* END BLOG POST TEXT */}
                {/* BLOG POST INNER IMAGE */}
                {!Tutorial.youTubeVideoCode && (
                  <div className='post-inner-img'>
                    <img
                      className='img-fluid'
                      src={Tutorial.tutorialPromoImage}
                      alt={Tutorial.tutorialTitle}
                      style={{
                        height: '585px',
                        width: '90%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                )}
                {/* BLOG POST TEXT SECTIONS*/}
                <div className='single-post-txt'>
                  {/* Title */}
                  {/* Loop over section of list */}
                  {Tutorial.tutorialSections.map((section, index) => {
                    if (section.sectionType === 'text') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>
                            {/* Text */}
                            <p className='p-lg'>
                              {section.sectionSectionExplanation}
                            </p>
                            {/* Digit List */}
                            <ol className='digit-list'>
                              {section.sectionList &&
                                section.sectionList.length > 0 &&
                                section.sectionList.map((listItem, index) => (
                                  <div key={index}>
                                    <li>
                                      <p className='p-lg'>
                                        {listItem.listText}
                                      </p>
                                    </li>
                                  </div>
                                ))}
                            </ol>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'Image') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>
                            {/* Image */}
                            <img
                              className='img-fluid'
                              src={section.sectionPicUrl}
                              alt={`${section.sectionTitle} Image`}
                              style={{
                                height: '585px',
                                width: '90%',
                                objectFit: 'contain',
                              }}
                            />
                            {/* Image Caption */}
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'Paragraph') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>

                            {/* Text */}
                            <p className='p-lg'>
                              {section.sectionSectionExplanation}
                            </p>

                            {/* Image */}

                            <img
                              className='img-fluid'
                              src={section.sectionPicUrl}
                              alt={`${section.sectionTitle} Image`}
                              style={{
                                height: '585px',
                                width: '90%',
                                objectFit: 'contain',
                              }}
                            />
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'videoAndContent') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>

                            {/* Text */}
                            <p className='p-lg'>
                              {section.sectionSectionExplanation}
                            </p>

                            {/* Video */}
                            <div className='video-preview'>
                              {/* Play Icon */}
                              <Iframe
                                url={
                                  'https://www.youtube.com/embed/' +
                                  section.youTubeVideoCode
                                }
                                width='90%'
                                height='585px'
                                id='youTubeVideoCode'
                                display='initial'
                                frameborder='0'
                                allowfullscreen
                                position='relative'
                              />
                            </div>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType == 'quote') {
                      return (
                        <React.Fragment>
                          {/* QUOTE */}
                          <div className='quote mt-30 mb-35'>
                            {/* Quote Text */}
                            <p className='p-xl'>
                              {section.sectionSectionExplanation}
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'textAndMultiImg') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>

                            {/* Text */}
                            <p className='p-lg'>
                              {section.sectionSectionExplanation}
                            </p>

                            <div className='row'>
                              {/* Inner Image #1 */}

                              {section.sectionImgs &&
                                section.sectionImgs.length > 0 &&
                                section.sectionImgs.map((img, index) => (
                                  <div
                                    key={index}
                                    className='col-md-6 top-img blog-post-img'
                                  >
                                    <React.Fragment>
                                      {/* Image */}
                                      <img
                                        className='img-fluid'
                                        src={img.imgUrl}
                                        alt={img.imgCaption}
                                        style={{
                                          height: '585px',
                                          width: '90%',
                                          objectFit: 'contain',
                                        }}
                                      />
                                      {/* Text */}
                                      <p>{img.imgCaption}</p>
                                    </React.Fragment>
                                  </div>
                                ))}
                            </div>
                          </section>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>{' '}
                {/* END BLOG POST TEXT */}
                {/* SINGLE POST SHARE LINKS */}
                <div className='row post-share-links d-flex align-items-center'>
                  {/* POST TAGS */}
                  {Tutorial.tutorialCategories &&
                    Tutorial.tutorialCategories.length > 0 && (
                      <div className='col-md-9 col-xl-8 post-tags-list'>
                        {/* POST TAGS */}
                        <p className='p-sm post-tag txt-500 txt-upcase'>
                          {Tutorial.tutorialCategories.map(
                            (category, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && <>&ensp;|&ensp;</>}
                                {category.categoryName}
                              </React.Fragment>
                            )
                          )}
                          &ensp;|&ensp;
                          <span>{Tutorial.tutorialPlatform}</span>
                        </p>
                      </div>
                    )}

                  {/* POST SHARE ICONS */}
                  <div className='col-md-3 col-xl-4 post-share-list text-end'>
                    <ul className='share-social-icons ico-25 text-center clearfix'>
                      <li>
                        <a
                          href={socialMediaLink.linkedInSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-linkedin'></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={socialMediaLink.facebookSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-facebook'></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={socialMediaLink.youtubeSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-youtube'></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>{' '}
                {/* END SINGLE POST SHARE */}
              </div>
            </div>
          </div>{' '}
          {/* END SINGLE POST CONTENT */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END SINGLE POST */}
      {/* ABOUT POST AUTHOR
			============================================= */}
      <div className='about-post-author bg-snow division'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='author-senoff'>
                {/* Avatar */}

                <img
                  src={Tutorial.authorProfile?.authorProfileImg}
                  alt='author-avatar'
                  style={{ height: '120px', width: '120px', display: 'none' }}
                />

                {/* Text */}
                <div className='author-senoff-txt'>
                  {/* Title */}
                  <h5 className='h5-xs'>Published by</h5>
                  <h5 className='h5-md'>
                    {Tutorial.authorProfile?.authorName}
                  </h5>
                  <p className='p-md'>{Tutorial.authorProfile?.authorBio}</p>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </div>{' '}
      {/* END ABOUT POST AUTHOR */}
      {/* POST COMMENTS (Future)
			============================================= */}
      {/* END POST COMMENTS */}
      {/* BLOG-1 NEXT
			============================================= */}
      {Array.isArray(Tutorials) &&
        Tutorials.length > 0 &&
        Tutorials[0] &&
        Tutorials[0].tutorialTitle && (
          <>
            <section
              id='blog-1'
              className='bg-whitesmoke-gradient wide-60 blog-section division'
            >
              <div className='container'>
                {/* SECTION TITLE */}
                <div className='row justify-content-center'>
                  <div className='col-lg-10 col-xl-8'>
                    <div className='section-title title-01 mb-70'>
                      <h2 className='h2-md'>Keep Reading...</h2>
                    </div>
                  </div>
                </div>
                {/* BLOG POSTS */}

                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                  {Tutorials.map((tutorial, index) => (
                    <React.Fragment key={index}>
                      {/* BLOG POST #1 */}
                      <div className='col'>
                        <div
                          id='bp-1-1'
                          className='blog-1-post mb-40 wow fadeInUp'
                        >
                          {/* BLOG POST IMAGE */}
                          <div className='blog-post-img'>
                            <div className='hover-overlay'>
                              <Link
                                to={`/tutorial/${tutorial.tutorialTitle}`}
                                onClick={() => updateUI(tutorial)}
                              >
                                <img
                                  className='img-fluid'
                                  src={tutorial.tutorialPromoImage}
                                  alt={`Tutorial ${tutorial.tutorialTitle} Image`}
                                  style={{
                                    height: '364px',
                                    width: '416px',
                                    objectFit: 'contain',
                                  }}
                                />
                              </Link>
                              <div className='item-overlay'></div>
                            </div>
                          </div>
                          {/* BLOG POST TEXT */}
                          <div className='blog-post-txt'>
                            {/* Post Tag */}
                            <p className='p-md post-tag'>
                              {tutorial.tutorialCategories.map(
                                (category, index) => (
                                  <React.Fragment key={index}>
                                    {index > 0 && <>&ensp;|&ensp;</>}
                                    {category.categoryName}
                                  </React.Fragment>
                                )
                              )}
                              &ensp;|&ensp;
                              {tutorial.recordCreatedDate
                                ? new Date(
                                    tutorial.recordCreatedDate
                                  ).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                  })
                                : ''}
                            </p>

                            {/* Post Link */}
                            <h5 className='h5-md'>
                              <Link
                                to={`/tutorial/${tutorial.tutorialTitle}`}
                                onClick={() => updateUI(tutorial)}
                              >
                                {tutorial.tutorialTitle}
                              </Link>
                            </h5>

                            {/* Text */}
                            <p className='p-lg'>
                              {' '}
                              {tutorial.tutorialOverview.length > 200
                                ? tutorial.tutorialOverview.slice(0, 200) +
                                  '...'
                                : tutorial.tutorialOverview}
                            </p>
                          </div>{' '}
                          {/* END BLOG POST TEXT */}
                        </div>
                      </div>{' '}
                      {/* END  BLOG POST #1 */}
                    </React.Fragment>
                  ))}
                </div>

                {/* END BLOG POSTS */}
              </div>

              {/* End container */}
            </section>{' '}
          </>
        )}
      {/* END BLOG-1 */}
      {/* NEWSLETTER-1
			============================================= */}
      <NewsletterForm />
      {/* END NEWSLETTER-1 */}
    </React.Fragment>
  );
};

export default TutorialDetail;
