import React from 'react';

const PortalLink = ({ portalUrl }) => (
  <a
    href={portalUrl}
    target='_blank'
    className='store btn btn-success'
    title='Visit our web portal'
    style={{
      width: '163px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <b style={{ display: 'flex', alignItems: 'center' }}>
      <span
        className='flaticon-worldwide'
        style={{ marginRight: '10px' }}
      ></span>
      <p
        style={{
          margin: 0,
          whiteSpace: 'nowrap',
          fontSize: '16px',
        }}
      >
        Web Portal
      </p>
    </b>
  </a>
);

export default PortalLink;
