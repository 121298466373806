import React from 'react';

const PricingSection = ({ pricingDetail }) => {
  return (
    <section
      id='pricing-1'
      className='bg-snow wide-70 pricing-section division'
    >
      {pricingDetail && (
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-xl-8'>
              <div className='section-title title-01 mb-80'>
                {/* Title */}
                <h2 className='h2-md'>{pricingDetail.title}</h2>

                {/* Text */}
                <p className='p-xl'>{pricingDetail.message}</p>
              </div>
            </div>
          </div>
          {/* PRICING TABLES */}
          <div className='pricing-1-row pc-25'>
            <div className='row row-cols-1 row-cols-md-3'>
              {/* FREE PLAN */}

              {pricingDetail.pricingPlans.map((plan, index) => (
                <div className='col' key={index}>
                  <div className='pricing-1-table bg-white rel mb-40 wow fadeInUp'>
                    {/* Hightlight Badge */}
                    {plan.isBestValue && (
                      <div className='badge-wrapper'>
                        <div className='highlight-badge bg-violet-red white-color'>
                          <h6 className='h6-md'>Best Value</h6>
                        </div>
                      </div>
                    )}

                    {/* Plan Price */}
                    <div className='pricing-plan highlight'>
                      {/* Plan Title */}
                      <h5 className='h5-xs'>{plan.title}</h5>

                      {/* Price */}
                      <sup className='dark-color'>$</sup>
                      <span className='dark-color'>{plan.priceInDollars}</span>
                      <sup className='validity dark-color'>
                        <span>.{plan.priceInCents}</span> / {plan.planPeriod}
                      </sup>
                      <p className='p-md'>{plan.message}</p>

                      {/* Pricing Plan Button */}
                      <a
                        href='https://app.savingpalz.com/signup'
                        target='_blank'
                        rel='noreferrer'
                        className={`btn btn-sm ${
                          plan.isBestValue
                            ? 'btn-violet-red tra-grey-hover'
                            : 'btn-tra-grey tra-violet-red-hover'
                        }`}
                      >
                        Select Plan
                      </a>
                    </div>
                  </div>
                </div>
              ))}

              {/* END ANNUAL PLAN */}
            </div>
          </div>{' '}
          {/* END PRICING TABLES */}
        </div>
      )}

      {/* End container */}
    </section>
  );
};

export default PricingSection;
