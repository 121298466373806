import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

const EmptyState = ({ lottieOptions, title, message, link, linkText }) => {
  return (
    <div className='row'>
      <Lottie options={lottieOptions} height={400} width={400} />
      <div className='text-center'>
        <h3>{title}</h3>
        <span>{message}</span>
      </div>
      <div className='mt-3 text-center'>
        <Link to={link} className='btn btn-success'>
          {linkText}
        </Link>
      </div>
    </div>
  );
};

export default EmptyState;
