import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
//new
import ReactPaginate from 'react-paginate';

import NewsletterForm from '../../../common/reusuable/newletter/newsletterForm';

import EmptyState from '../../../common/reusuable/empty/EmptyState';
import no_results from '../../../common/reusuable/animations/no-result-search.json';
import LoadingView from '../../../common/reusuable/loading/loadingView';
import { getAllTutorialsPaginated } from '../../../../services/general/tutorials/tutorialsService';
import { Helmet } from 'react-helmet';

const Tutorials = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [pageCount, setPageCount] = useState(0);

  const [tutorialsCount, setTutorialsCount] = useState(0);

  const containerClass = 'pagination-light';
  const subContainerClass = 'pages pagination-light';
  const activeClass = 'active-light';

  //models
  const [pageHeaderDetail] = useState({
    title: 'SavingPalz Tutorials',
  });

  const emptyBlogsLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_results,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //list of objects (test will use api soon)
  const [Tutorials, setTutorials] = useState([
    {
      tutorialId: '',
      tutorialTitle: '',
      videoUploadDate: '',
      youTubeVideoCode: '',
      tutorialOverview: '',
      tutorialPromoImage: '',
      deviceSpecificImages: [
        {
          deviceType: '',
          deviceImageUrl: '',
        },
      ],
      tutorialTags: [
        {
          tagId: '',
          tagName: '',
          tagDescription: '',
        },
      ],
      commentCount: 0,
      authorName: '',
      authorProfile: {
        authorId: '',
        authorName: '',
        authorProfileImgUrl: '',
        authorProfileOverview: '',
      },
      tutorialCategories: [
        {
          categoryId: '',
          categoryName: '',
          categoryDescription: '',
        },
      ],
      tutorialSections: [
        {
          sectionId: '',
          sectionType: '',
          sectionTitle: '',
          sectionPicUrl: '',
          deviceSpecificImages: [
            {
              deviceType: '',
              deviceImageUrl: '',
            },
          ],
          sectionSectionExplanation: '',
          mathEquationLogic: '',
          sourceCodeSnippets: [
            {
              sourceCodeTitle: '',
              sourceCodeExplanation: '',
              sourceCodeDetail: '',
              languageId: '',
              languageName: '',
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: '',
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: '',
              authorProfileUrl: '',
              authorProfileOverview: '',
              authorTitle: '',
            },
          ],
          referenceDetail: '',
        },
      ],
      recordCreatedDate: '',
      isDraft: false,
      tutorialPlatform: '',
      locationInApp: '',
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    //set at the top of the screen
    window.scrollTo(0, 0);

    getTutorials();
  }, []);
  //can handle destroying and reseting variables

  const getTutorials = async () => {
    setIsLoading(true);

    getAllTutorialsPaginated(currentPage, pageSize)
      .then((response) => {
        const { tutorials, pageCount } = response.data;

        if (tutorials !== undefined && tutorials.length > 0) {
          setTutorials(tutorials);
          setTutorialsCount(tutorials.length);
          setPageCount(pageCount);
          setIsLoading(false);
        } else {
          toast.warning('No Blog Found');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz | Tutorials</title>
        <meta name='description' content='SavingPalz Tutorials' />

        <meta
          name='keywords'
          content='SavingPalz, Tutorials, SavingPalz Tutorials, How to, How to use SavingPalz, SavingPalz How to, SavingPalz Tutorials, SavingPalz Blog, SavingPalz Blog Posts'
        />

        <meta property='og:title' content='SavingPalz | Tutorials' />
        <meta property='og:description' content='SavingPalz Tutorials' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://savingpalz.com/tutorials' />
      </Helmet>
      {/* PRELOADER SPINNER
		============================================= */}
      {isLoading && <LoadingView />}
      {/* BLOG POSTS LISTING
			============================================= */}
      <section
        id='blog-page'
        className='bg-snow wide-50  blog-page-section division'
      >
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-8'>
              <div className='section-title title-02 mb-85'>
                <h2 className='h2-xs'>{pageHeaderDetail.title}</h2>
              </div>
            </div>
          </div>
          {/* POSTS WRAPPER */}
          <div className='posts-wrapper'>
            {/* BLOG POSTS CATEGORY */}
            <div className='row'>
              <div className='col-md-12'>
                <h5 className='h5-lg posts-category'>Our Tutorials</h5>
              </div>
            </div>
            {/* BLOG POSTS */}
            {Tutorials && Tutorials.length > 0 ? (
              <div className='row'>
                {Tutorials &&
                  Tutorials.map((tutorial, index) => (
                    <div key={index} className='blog-3-post masonry-image'>
                      {/* BLOG POST IMAGE */}
                      <div className='blog-post-img'>
                        <div className='hover-overlay'>
                          <Link to={`/tutorial/${tutorial.tutorialTitle}`}>
                            <img
                              className='img-fluid'
                              src={tutorial.tutorialPromoImage}
                              alt='blog-post-image'
                              style={{
                                height: 260,
                                width: 389,
                                objectFit: tutorial.tutorialPlatform
                                  .toLowerCase()
                                  .includes('ios')
                                  ? 'cover'
                                  : 'contain',
                              }}
                            />
                          </Link>
                          <div className='item-overlay'></div>
                        </div>
                      </div>
                      {/* BLOG POST TEXT */}
                      <div className='blog-post-txt'>
                        {/* Post Tag */}
                        <p className='p-md post-tag'>
                          {tutorial.tutorialCategories.length > 0 && (
                            <>
                              {tutorial.tutorialCategories.map(
                                (category, index) => (
                                  <React.Fragment key={index}>
                                    {index > 0 && <>&ensp;|&ensp;</>}
                                    {category.categoryName}
                                  </React.Fragment>
                                )
                              )}
                            </>
                          )}
                          &ensp;|&ensp;
                          <span>{tutorial.tutorialPlatform}</span>
                          &ensp;|&ensp;
                          {tutorial.recordCreatedDate
                            ? new Date(
                                tutorial.recordCreatedDate
                              ).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              })
                            : ''}
                        </p>

                        {/* Post Link */}
                        <h5 className='h5-md'>
                          <Link to={`/tutorial/${tutorial.tutorialTitle}`}>
                            {tutorial.tutorialTitle}
                          </Link>
                        </h5>

                        {/* Post Meta */}
                        <div className='post-meta'>
                          <p className='p-md'>
                            {tutorial.tutorialOverview.length > 200
                              ? tutorial.tutorialOverview.slice(0, 200) + '...'
                              : tutorial.tutorialOverview}
                          </p>
                        </div>
                      </div>{' '}
                      {/* END BLOG POST TEXT */}
                    </div>
                  ))}
              </div>
            ) : (
              <EmptyState
                lottieOptions={emptyBlogsLottieOptions}
                title='No Blogs Found'
                message='No blogs found at the moment'
                link='/'
                linkText='Go Back Home'
              />
            )}

            {/* END BLOG POSTS */}
          </div>{' '}
          {/* END POSTS WRAPPER */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END BLOG POSTS LISTING */}
      {/* PAGE PAGINATION TODO
			============================================= */}
      <div className='bg-snow pb-100 page-pagination division'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              {pageCount > 1 && (
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => {
                    const selectedPage = data.selected + 1;
                    setCurrentPage(selectedPage);
                  }}
                  containerClassName={containerClass}
                  subContainerClassName={subContainerClass}
                  activeClassName={activeClass}
                />
              )}
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </div>{' '}
      {/* END PAGE PAGINATION */}
      {/* NEWSLETTER-1
			============================================= */}
      <NewsletterForm />
      {/* END NEWSLETTER-1 */}
    </React.Fragment>
  );
};

export default Tutorials;
