import React, { Component, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../../styletheme/logos/SavingPalzLogo.png';

const GeneralFooter = ({ privacyPolicyLink, termsAndConditionsLink }) => {
  const [youtubeLink, setYoutubeLink] = useState(
    'https://www.youtube.com/@SavingPalz'
  );
  const [linkedinLink, setLinkedinLink] = useState(
    'https://www.linkedin.com/company/savingpalz'
  );
  const [xTwitterLink, setXTwitterLink] = useState(
    'https://twitter.com/SavingPalz'
  );

  const [facebookLink, setFacebookLink] = useState(
    'https://www.facebook.com/p/SavingPalz-61550599034370/'
  );
  return (
    <React.Fragment>
      {/* FOOTER-3
			============================================= */}
      <footer id='footer-3' className='footer division'>
        <div className='container'>
          {/* FOOTER CONTENT */}
          <div className='row'>
            {/* FOOTER INFO */}
            <div className='col-lg-5 col-xl-4'>
              <div className='footer-info mb-40'>
                {/* Footer Logo */}
                <Link to={'/'}>
                  <img
                    className='footer-logo mb-25'
                    src={logo}
                    alt='footer-logo'
                    style={{ maxHeight: '70px', width: '70px' }}
                  />
                </Link>

                {/* Text */}
                <p className='p-md'>
                  SavingPalz is a financial application that helps you to manage
                  your budget, track your expenses, and save money.
                </p>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className='col-sm-4 col-lg-2 col-xl-2 offset-xl-2'>
              <div className='footer-links mb-40'>
                {/* Title */}
                <h6 className='h6-xl'>About</h6>

                {/* Footer Links */}
                <ul className='foo-links text-secondary clearfix'>
                  <li>
                    <p className='p-md'>
                      <a href='/about'>About Us</a>
                    </p>
                  </li>

                  <li>
                    <p className='p-md'>
                      <a href='/pressKit'>Press & Media</a>
                    </p>
                  </li>

                  <li>
                    <p className='p-md'>
                      <a href='/contactus'>Contact Us</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className='col-sm-4 col-lg-2'>
              <div className='footer-links mb-40'>
                {/* Title */}
                <h6 className='h6-xl'>Discover</h6>

                {/* Footer List */}
                <ul className='foo-links text-secondary clearfix'>
                  <li>
                    <p className='p-md'>
                      <a href='/blogs'> Blogs</a>
                    </p>
                  </li>

                  <li>
                    <p className='p-md'>
                      <a href='/pricing'>Plans & Pricing</a>
                    </p>
                  </li>

                  <li>
                    <p className='p-md'>
                      <a href='/faq'>FAQs</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            {/* FOOTER LINKS */}
            <div className='col-sm-4 col-lg-3 col-xl-2'>
              <div className='footer-links mb-40'>
                {/* Title */}
                <h6 className='h6-xl'>Connect With Us</h6>

                {/* Social Links */}
                <ul className='footer-socials text-secondary ico-25 clearfix'>
                  <li>
                    <a href={facebookLink} target='_blank'>
                      <span className='flaticon-facebook'></span>
                    </a>
                  </li>
                  <li>
                    <a href={xTwitterLink} target='_blank'>
                      <span className='flaticon-twitter'></span>
                    </a>
                  </li>

                  <li>
                    <a href={linkedinLink} target='_blank'>
                      <span className='flaticon-linkedin'></span>
                    </a>
                  </li>
                  <li>
                    <a href={youtubeLink} target='_blank'>
                      <span className='flaticon-youtube'></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>{' '}
          {/* END FOOTER CONTENT */}
          <hr />
          {/* BOTTOM FOOTER */}
          <div className='bottom-footer'>
            <div className='row row-cols-1 row-cols-md-2 d-flex align-items-center'>
              {/* FOOTER COPYRIGHT */}
              <div className='col'>
                <div className='footer-copyright'>
                  <p>
                    Copyright &copy; {new Date().getFullYear()} SavingPalz.
                    Developed by Gurenter LLC. All Rights Reserved
                  </p>
                </div>
              </div>

              {/* BOTTOM FOOTER LINKS */}
              <div className='col'>
                <ul className='bottom-footer-list text-secondary text-end'>
                  <li className='first-li'>
                    <p>
                      <a href={privacyPolicyLink} target='_blank'>
                        Privacy Policy
                      </a>
                    </p>
                  </li>
                  <li className='last-li'>
                    <p>
                      <a href={termsAndConditionsLink} target='_blank'>
                        Terms & Conditions
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* BOTTOM FOOTER */}
        </div>
      </footer>{' '}
      {/* FOOTER-3 */}
    </React.Fragment>
  );
};

export default GeneralFooter;
