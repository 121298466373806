import React, { useEffect, useState } from 'react';
import PricingSection from '../../common/reusuable/pricing/pricingSection';
import FAQSection from '../../common/reusuable/faq/faqSection';
import PricingTableComparisonSection from '../../common/reusuable/pricing/pricingTableComparisonSection';

import visa from '../../../styletheme/public/images/png-icons/visa.png';
import amPay from '../../../styletheme/public/images/png-icons/am.png';
import discover from '../../../styletheme/public/images/png-icons/discover.png';
import jcb from '../../../styletheme/public/images/png-icons/jcb.png';

import { Helmet } from 'react-helmet';

const PricingPage = () => {
  const [portalSignUpUrl, setPortalSignUpUrl] = useState(
    'https://app.savingpalz.com/signup'
  );
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about SavignPalz and its offerings',
  });
  const [pricingDetail, setPricingDetail] = useState({
    title: 'Simple Pricing Structure',
    message:
      'Our pricing is simple and easy to understand. We offer a 7-day free trial, after the trial, you can choose to continue with our free plan or upgrade to our pro plan.',
    pricingPlans: [
      {
        title: 'Free Plan',
        message:
          'Our free plan includes basic features to help you manage your finances. These include budgeting and expense tracking.',
        price: 'Free',
        isFree: true,
        priceInDollars: 0,
        priceInCents: 0,
        planPeriod: 'forever',
      },
      {
        title: 'Monthly Pro Plan',
        message:
          'Our pro plan includes what is in the free plan plus budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
        price: '$9.99/month',
        isFree: false,
        priceInDollars: 9,
        priceInCents: 99,
        planPeriod: 'month',
      },
      {
        title: 'Annual Pro Plan',
        message:
          'Our pro plan includes what is in the free plan plus budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
        price: '$119.88/year',
        isBestValue: true,
        isFree: false,
        priceInDollars: 119,
        priceInCents: 88,
        planPeriod: 'year',
      },
    ],
  });

  const [frequentlyAskedQuestion, setFrequentlyAskedQuestion] = useState({
    title: 'Everything you need to know before getting started',
    subTitle: 'Frequently Asked Questions',
    questions: [
      {
        question: 'What is SavingPalz?',
        answer:
          'SavingPalz is a budgeting and expense tracking app that helps you manage your finances.',
      },
      {
        question: 'How much does SavingPalz cost?',
        answer:
          'SavingPalz offers a free plan and a pro plan. The free plan includes basic features to help you manage your finances. The pro plan is $9.99 per month and includes additional features such as budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
      },
      {
        question: 'Is SavingPalz secure?',
        answer:
          'Yes, SavingPalz takes security seriously. All data is encrypted and stored securely.',
      },
      {
        question: 'How long is the free trial?',
        answer:
          'The free trial is 7 days. After the trial, you can choose to continue with our free plan or upgrade to our pro plan.',
      },
      {
        question: 'What platforms is SavingPalz available on?',
        answer:
          'SavingPalz is available on the web and iOS. An Android version is coming soon.',
      },
      {
        question: 'Can I cancel my subscription at any time?',
        answer:
          'Yes, you can cancel your subscription at any time. Your subscription will remain active until the end of the billing period.',
      },
    ],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz - Pricing</title>
        <meta
          name='description'
          content='Our pricing is simple and easy to understand. We offer a 7-day free trial, after the trial, you can choose to continue with our free plan or upgrade to our pro plan.'
        />

        <meta
          name='keywords'
          content='pricing, pricing structure, pricing plans, pricing comparison'
        />

        <meta property='og:title' content='SavingPalz - Pricing' />
        <meta
          property='og:description'
          content='Our pricing is simple and easy to understand. We offer a 7-day free trial, after the trial, you can choose to continue with our free plan or upgrade to our pro plan.'
        />
      </Helmet>
      {/* PRICING-2
			============================================= */}
      <section
        id='pricing-2'
        className='bg-snow pb-60 pricing-section division'
      >
        <div className='container'>
          {/* PRICING TABLES */}
          <PricingSection pricingDetail={pricingDetail} />
          {/* END PRICING TABLES */}
          {/* PRICING COMPARE  */}
          <PricingTableComparisonSection />
          {/* END PRICING COMPARE */}
          {/* PRICING NOTICE TEXT */}
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='pricing-notice text-center mb-40'>
                <p className='p-md'>
                  The above prices do not include applicable taxes; they are
                  based on your billing address. The final price will be
                  displayed on the checkout page before payment completion.
                </p>
              </div>
            </div>
          </div>
          {/* PAYMENT METHODS */}
          <div className='payment-methods pc-30'>
            <div className='row row-cols-1 row-cols-md-3'>
              {/* Payment Methods */}
              <div className='col col-lg-5'>
                <div className='pbox mb-40'>
                  {/* Title */}
                  <h6 className='h6-md'>Accepted Payment Methods</h6>

                  {/* Payment Icons */}
                  <ul className='payment-icons ico-50'>
                    <li>
                      <img src={visa} alt='payment-icon' />
                    </li>
                    <li>
                      <img src={amPay} alt='payment-icon' />
                    </li>
                    <li>
                      <img src={discover} alt='payment-icon' />
                    </li>

                    <li>
                      <img src={jcb} alt='payment-icon' />
                    </li>
                  </ul>
                </div>
              </div>

              {/* Payment Guarantee */}
              <div className='col col-lg-4'>
                <div className='pbox mb-40'>
                  {/* Title */}
                  <h6 className='h6-md'>No Advertisements Ever</h6>

                  {/* Text */}
                  <p>
                    Whether you choose the free or pro plan, you will never see
                    any advertisements on SavingPalz. We hate ads as much as you
                    do.
                  </p>
                </div>
              </div>

              {/* Payment Encrypted */}
              <div className='col col-lg-3'>
                <div className='pbox mb-40'>
                  {/* Title */}
                  <h6 className='h6-md'>You are in Control </h6>

                  {/* Text */}
                  <p>
                    You can cancel your subscription at any time. Your pro
                    account benefits will remain active until the end of the
                    billing period. But you can still use the free plan to do
                    basic budgeting and expense tracking forever.
                  </p>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* END PAYMENT METHODS */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END PRICING-2 */}
      {/* CALL TO ACTION-4
			============================================= */}
      <section id='cta-4' className='cta-section division'>
        <div className='cta-4-holder bg-snow'>
          <div className='container'>
            <div className='bg-white cta-4-wrapper'>
              <div className='row d-flex align-items-center'>
                {/* CALL TO ACTION TEXT */}
                <div className='col-lg-7 col-lg-8'>
                  <div className='cta-4-txt'>
                    <h4 className='h4-xl'>
                      Try SavingPalz free for 7 days. Start your trial now and
                      you can always use the Free Plan forever.
                    </h4>
                  </div>
                </div>

                {/* CALL TO ACTION BUTTON */}
                <div className='col-lg-4'>
                  <div className='text-end'>
                    <div className='cta-4-btn text-center'>
                      <a
                        href={portalSignUpUrl}
                        target='_blank'
                        rel='noreferrer'
                        className='btn btn-sm btn-violet-red tra-grey-hover'
                      >
                        Get Started Now
                      </a>
                      <p>
                        <a href='/faq'>Read The FAQs</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* End container */}
        </div>
      </section>{' '}
      {/* END CALL TO ACTION-4 */}
      {/* FAQs-2
			============================================= */}
      <FAQSection frequentlyAskedQuestion={frequentlyAskedQuestion} />
      {/* END FAQs-2 */}
    </React.Fragment>
  );
};

export default PricingPage;
