import React from 'react';
import { Link } from 'react-router-dom';

const FAQSection = ({ frequentlyAskedQuestion }) => {
  return (
    <section id='faqs-2' className='wide-60 faqs-section division'>
      {frequentlyAskedQuestion && (
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-8'>
              <div className='section-title title-02 mb-75'>
                {/* Section ID */}
                <span className='section-id txt-upcase'>
                  {frequentlyAskedQuestion.subTitle}
                </span>

                {/* Title */}
                <h2 className='h2-xs'>{frequentlyAskedQuestion.title}</h2>
              </div>
            </div>
          </div>
          <div className='faqs-2-questions'>
            <div className='row row-cols-1 row-cols-lg-2'>
              {frequentlyAskedQuestion.questions.map((question, index) => (
                <div className='col' key={index}>
                  <div className='questions-holder pr-15'>
                    <div className='question wow fadeInUp'>
                      <h5 className='h5-md'>{question.question}</h5>
                      <p className='p-lg'>{question.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* MORE QUESTIONS BUTTON */}
          <div className='row'>
            <div className='col'>
              <div className='more-questions'>
                <h5 className='h5-sm'>
                  Have more questions?{' '}
                  <Link to={'/contactus'}>Ask your question here</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* End container */}
    </section>
  );
};

export default FAQSection;
