import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import logo from './logo.svg';
//import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from './components/public_pages/homepage';
import About from './components/public_pages/about';
import NavBar from './components/common/navigation/navigation_bar';
import GeneralFooter from './components/common/navigation/general_footer';
import ContactUs from './components/public_pages/contact/contactus';
//import TenantApplication from "./components/portal/tenant/tenantapplication";
import Blogs from './components/public_pages/blogs/blogs';
import BlogDetail from './components/public_pages/blogs/detail/blogdetail';
import NotFoundPage from './components/public_pages/notfound/notfoundpage';
import Tutorials from './components/public_pages/tutorials/general/tutorials';
import TutorialDetail from './components/public_pages/tutorials/general/detail/tutorialdetail';
import PricingPage from './components/public_pages/pricing/pricingPage';
import FAQPage from './components/public_pages/faq/faqPage';
import DownloadPage from './components/public_pages/download/downloadPage';
import PressKitPage from './components/public_pages/press/pressKitPage';
//Check these out
/*
Need the admin app to be copied and connected to the promo app
*/
//blogs promo, new releases etc
//TODO: the pricing page then the downloads -> launch

class App extends Component {
  state = {
    termsAndConditionsLink:
      'https://gurenter.notion.site/SavingPalz-Terms-of-Use-EULA-aa5fcadf56304468ab3febb06aeab13d',
    privacyPolicyLink:
      'https://gurenter.notion.site/SavingPalz-Privacy-Policy-e6772d3784a24587a30ba293f716c50b',
  };

  async componentDidMount() {}

  render() {
    const { termsAndConditionsLink, privacyPolicyLink } = this.state;

    return (
      <React.Fragment>
        <div>
          <ToastContainer />

          <div id='page' className='page'>
            <NavBar />
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/about' element={<About />} />
              <Route path='/contactus' element={<ContactUs />} />

              <Route path='/blogs' element={<Blogs />} />

              <Route path='/blog' element={<BlogDetail />}>
                <Route path=':blogTitle' element={<BlogDetail />} />
              </Route>

              <Route path='/tutorials' element={<Tutorials />} />

              <Route path='/tutorial' element={<TutorialDetail />}>
                <Route path=':tutorialTitle' element={<TutorialDetail />} />
              </Route>

              <Route path='/pricing' element={<PricingPage />} />

              <Route path='/faq' element={<FAQPage />} />

              <Route path='/download' element={<DownloadPage />} />

              <Route path='/pressKit' element={<PressKitPage />} />

              <Route path='/*' element={<NotFoundPage />} />
            </Routes>

            <GeneralFooter
              termsAndConditionsLink={termsAndConditionsLink}
              privacyPolicyLink={privacyPolicyLink}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
