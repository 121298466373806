import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import {
  fetchAllBlogs,
  fetchHighlightedBlog,
  getAllBlogsPaginated,
} from '../../../services/general/blogs/blogsService';
import { toast } from 'react-toastify';
//new
import ReactPaginate from 'react-paginate';
import NewsletterForm from '../../common/reusuable/newletter/newsletterForm';
import LoadingView from '../../common/reusuable/loading/loadingView';
import { Spinner } from 'react-bootstrap';
//lottie
import Lottie from 'react-lottie';
import no_results from '../../common/reusuable/animations/no-result-search.json';
import EmptyState from '../../common/reusuable/empty/EmptyState';
import { Helmet } from 'react-helmet';

const Blogs = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [pageCount, setPageCount] = useState(0);

  const [blogsCount, setBlogsCount] = useState(0);

  const containerClass = 'pagination-light';
  const subContainerClass = 'pages pagination-light';
  const activeClass = 'active-light';

  const emptyBlogsLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_results,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //models
  const [pageHeaderDetail] = useState({
    title: 'Relevant news about SavingPalz and its offerings',
  });
  const [BlogHightlight, setBlogHighlight] = useState({
    categoryName: '',
    blogId: '',
    blogTitle: '',
    blogDateTime: '',
    blogOverview: '',
    blogPromoImg: '', //web optimised
    blogSections: [
      {
        //todo soon
      },
    ],
    numberOfComments: 0,
  });
  //list of objects (test will use api soon)
  const [Blogs, setBlogs] = useState([
    {
      categoryName: '',
      blogId: '',
      blogTitle: '',
      blogDateTime: '',
      blogOverview: '',
      blogPromoImg: '', //web optimised
      blogSections: [
        {
          //todo soon
        },
      ],
      numberOfComments: 0,
    },
  ]);

  //add the Lottie animations next
  useEffect(() => {
    //set at the top of the screen
    setIsLoading(true);
    window.scrollTo(0, 0);

    getHighlightedBlog();

    getBlogs();
  }, []);
  //can handle destroying and reseting variables

  const getHighlightedBlog = async () => {
    try {
      const { data: BlogHightlight } = await fetchHighlightedBlog();
      //console.log(BlogHightlight);
      setBlogHighlight(BlogHightlight);
    } catch (error) {
      //console.error(error);
      // Handle the error here
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getBlogs = async () => {
    //setIsLoading(true);

    getAllBlogsPaginated(currentPage, pageSize)
      .then((response) => {
        const { blogs, pageCount } = response.data;

        //console.log(`Blog Count ${blogs.length}}`);

        if (blogs !== undefined && blogs.length > 0) {
          //console.log("Blogs");
          //console.log(blogs);
          setBlogs(blogs);
          setBlogsCount(blogs.length);
          setPageCount(pageCount);
          setIsLoading(false);
        } else {
          //toast.warning("No Blog Found");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Blogs | SavingPalz</title>
        <meta
          name='description'
          content='Relevant news about SavingPalz and its offerings'
        />
        <meta
          name='keywords'
          content='SavingPalz, Blogs, News, Offerings, Finance, Savings, Tips, Budgeting, Investments, Loans, Insurance, Financial Literacy, Financial Freedom, Financial Independence, Financial Planning, Financial Management, Financial Wellness, Financial Wellbeing, Financial Health'
        />

        <meta property='og:title' content='Blogs | SavingPalz' />
        <meta
          property='og:description'
          content='Relevant news about SavingPalz and its offerings'
        />
        <meta property='og:url' content='https://savingpalz.com/blogs' />
        <meta property='og:type' content='website' />
      </Helmet>
      {/* PRELOADER SPINNER
		============================================= */}
      {isLoading && <LoadingView />}
      {/* BLOG POSTS LISTING
			============================================= */}
      <section
        id='blog-page'
        className='bg-snow wide-50 blog-page-section division'
      >
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-8'>
              <div className='section-title title-02 mb-85'>
                <h2 className='h2-xs'>{pageHeaderDetail.title}</h2>
              </div>
            </div>
          </div>
          {/* FEATURED POST */}
          {BlogHightlight.blogId !== '' ? (
            <div className='rel blog-post-wide featured-post'>
              <div className='row d-flex align-items-center'>
                {/* Featured Badge */}
                <div
                  className='featured-badge ico-25 bg-whitesmoke yellow-color'
                  hidden
                >
                  <span className='flaticon-star-1'></span>
                </div>
                {/* BLOG POST IMAGE */}
                <div className='col-lg-7 blog-post-img'>
                  <div className='hover-overlay'>
                    <Link to={`/blog/${BlogHightlight.blogTitle}`}>
                      {!BlogHightlight.blogPromoImg ? (
                        <Spinner animation='border' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </Spinner>
                      ) : (
                        <img
                          className='img-fluid'
                          src={BlogHightlight.blogPromoImg}
                          style={{
                            height: 400,
                            width: 680,
                            objectFit: 'contain',
                          }}
                          alt='Featured Blog Image'
                        />
                      )}
                    </Link>
                    <div className='item-overlay'></div>
                  </div>
                </div>
                {/* BLOG POST TEXT */}
                <div className='col-lg-5 blog-post-txt'>
                  {/* Post Tag */}
                  <p className='p-md post-tag'>
                    {BlogHightlight.categoryName} &ensp;|&ensp;{' '}
                    {BlogHightlight.recordCreatedDate
                      ? new Date(
                          BlogHightlight.recordCreatedDate
                        ).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })
                      : ''}
                  </p>

                  {/* Post Link */}
                  <h5 className='h5-xl'>
                    <Link to={`/blog/${BlogHightlight.blogTitle}`}>
                      {BlogHightlight.blogTitle}
                    </Link>
                  </h5>

                  {/* Text */}
                  <p className='p-lg'>{BlogHightlight.blogOverview}...</p>

                  {/* Post Meta */}
                  <div className='post-meta'>
                    <p className='p-md' hidden>
                      {BlogHightlight.numberOfComments} Comments
                    </p>
                  </div>
                </div>{' '}
                {/* END BLOG POST TEXT */}
              </div>{' '}
              {/* End row */}
            </div>
          ) : (
            <EmptyState
              lottieOptions={emptyBlogsLottieOptions}
              title='No Highlight Blog Found'
              message='No highlight blog found at the moment'
              link='/'
              linkText='Go Back Home'
            />
          )}
          {/* END FEATURED POST */}
          {/* POSTS WRAPPER */}
          <div className='posts-wrapper'>
            {/* BLOG POSTS CATEGORY */}
            <div className='row'>
              <div className='col-md-12'>
                <h5 className='h5-lg posts-category'>Our Blogs</h5>
              </div>
            </div>
            {/* BLOG POSTS */}

            {Blogs && Blogs.length > 0 ? (
              <div className='row'>
                {Blogs &&
                  Blogs.map((blog, index) => (
                    <React.Fragment>
                      {/* Start of list */}
                      {/* BLOG POST #2 */}
                      {isLoading ? (
                        <Placeholder as='div' animation='glow'>
                          <Placeholder.Image variant='rectangular' />
                          <Placeholder xs={6} />
                          <Placeholder xs={4} />
                          <Placeholder xs={6} />
                          <Placeholder xs={8} />
                        </Placeholder>
                      ) : (
                        <div key={index} className='blog-3-post masonry-image'>
                          {/* BLOG POST IMAGE */}
                          <div className='blog-post-img'>
                            <div className='hover-overlay'>
                              <Link to={`/blog/${blog.blogTitle}`}>
                                <img
                                  className='img-fluid'
                                  src={blog.blogPromoImg}
                                  alt='blog-post-image'
                                  style={{
                                    height: 260,
                                    width: 389,
                                    objectFit: 'contain',
                                  }}
                                />
                              </Link>
                              <div className='item-overlay'></div>
                            </div>
                          </div>
                          {/* BLOG POST TEXT */}
                          <div className='blog-post-txt'>
                            {/* Post Tag */}
                            <p className='p-md post-tag'>
                              {blog.categoryName} &ensp;|&ensp;
                              {blog.recordCreatedDate
                                ? new Date(
                                    blog.recordCreatedDate
                                  ).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                  })
                                : ''}
                            </p>

                            {/* Post Link */}
                            <h5 className='h5-md'>
                              <Link to={`/blog/${blog.blogTitle}`}>
                                {blog.blogTitle}
                              </Link>
                            </h5>

                            {/* Post Meta */}
                            <div className='post-meta'>
                              <p className='p-md' hidden>
                                {blog.numberOfComments} Comments
                              </p>
                            </div>
                          </div>{' '}
                          {/* END BLOG POST TEXT */}
                        </div>
                      )}{' '}
                      {/* END BLOG POST #2 */}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <EmptyState
                lottieOptions={emptyBlogsLottieOptions}
                title='No Blogs Found'
                message='No blogs found at the moment'
                link='/'
                linkText='Go Back Home'
              />
            )}

            {/* END BLOG POSTS */}
          </div>{' '}
          {/* END POSTS WRAPPER */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END BLOG POSTS LISTING */}
      {/* PAGE PAGINATION TODO
			============================================= */}
      <div className='bg-snow pb-100 page-pagination division'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              {pageCount > 1 && (
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => {
                    const selectedPage = data.selected + 1;
                    setCurrentPage(selectedPage);
                  }}
                  containerClassName={containerClass}
                  subContainerClassName={subContainerClass}
                  activeClassName={activeClass}
                />
              )}
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </div>{' '}
      {/* END PAGE PAGINATION */}
      {/* NEWSLETTER-1
			============================================= */}
      <NewsletterForm />
      {/* END NEWSLETTER-1 */}
    </React.Fragment>
  );
};

export default Blogs;
