import React, { useState } from 'react';
import FAQSection from '../../common/reusuable/faq/faqSection';
import { Helmet } from 'react-helmet';

const FAQPage = () => {
  const [frequentlyAskedQuestion, setFrequentlyAskedQuestion] = useState({
    title: 'Everything you need to know before getting started',
    subTitle: 'Frequently Asked Questions',
    questions: [
      {
        question: 'What is SavingPalz?',
        answer:
          'SavingPalz is a budgeting and expense tracking app that helps you manage your finances.',
      },
      {
        question: 'How much does SavingPalz cost?',
        answer:
          'SavingPalz offers a free plan and a pro plan. The free plan includes basic features to help you manage your finances. The pro plan is $9.99 per month and includes additional features such as budget goal tracking, collaboration with your Pal/Palz, Reports and much more coming soon.',
      },
      {
        question: 'Is SavingPalz secure?',
        answer:
          'Yes, SavingPalz takes security seriously. All data is encrypted and stored securely.',
      },
      {
        question: 'How long is the free trial?',
        answer:
          'The free trial is 7 days. After the trial, you can choose to continue with our free plan or upgrade to our pro plan.',
      },
      {
        question: 'What platforms is SavingPalz available on?',
        answer:
          'SavingPalz is available on the web and iOS. An Android version is coming soon.',
      },
      {
        question: 'Can I cancel my subscription at any time?',
        answer:
          'Yes, you can cancel your subscription at any time. Your subscription will remain active until the end of the billing period.',
      },
    ],
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz - FAQ</title>
        <meta
          name='description'
          content='Have a question? Check out our FAQ section to find answers to common questions about SavingPalz.'
        />
        <meta
          name='keywords'
          content='faq, frequently asked questions, savingpalz faq, savingpalz frequently asked questions'
        />
        <meta property='og:title' content='SavingPalz - FAQ' />
        <meta
          property='og:description'
          content='Have a question? Check out our FAQ section to find answers to common questions about SavingPalz.'
        />

        <meta
          name='twitter:card'
          content='Have a question? Check out our FAQ section to find answers to common questions about SavingPalz.'
        />
        <meta name='twitter:title' content='SavingPalz - FAQ' />
        <meta
          name='twitter:description'
          content='Have a question? Check out our FAQ section to find answers to common questions about SavingPalz.'
        />
      </Helmet>
      <FAQSection frequentlyAskedQuestion={frequentlyAskedQuestion} />
      {/*CALL TO ACTION-7
			============================================= */}
      <div id='cta-7' className='cta-section division'>
        <div className='container'>
          <div className='cta-7-wrapper bg-snow'>
            <div className='row d-flex align-items-center'>
              {/*TEXT */}
              <div className='col-lg-9'>
                <div className='cta-7-txt'>
                  <p className='p-xl'>
                    Still have a question? Don't worry, our customer care team
                    is ready to help you with any questions or problems
                  </p>
                </div>
              </div>

              {/*BUTTON */}
              <div className='col-lg-3 text-end'>
                <div className='cta-7-btn'>
                  <a
                    href='/contactus'
                    className='btn btn-skyblue tra-grey-hover'
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>{' '}
            {/*End row */}
          </div>{' '}
          {/*End cta-7-wrapper */}
        </div>{' '}
        {/*End container */}
      </div>{' '}
      {/*END CALL TO ACTION-7 */}
    </React.Fragment>
  );
};

export default FAQPage;
