import React from 'react';
import '../../../App.css';
const Input = ({
  name,
  label,
  value,
  placeholder,
  type,
  className,
  error,
  onChange,
}) => {
  return (
    <React.Fragment>
      <section className='col-lg-4 col-md-4'>
        <p classname='p-lg'>{label}: </p>
        <input
          className={className}
          type={type}
          id={name}
          onChange={onChange}
          value={value}
          required
          data-error={error}
          placeholder={placeholder}
          name={name}
        />
      </section>
    </React.Fragment>
  );
};

export default Input;

{
  /*<div className='form-group'>
			<label className='form-label'>
				{label}
				<span className='text-danger'>*</span>
			</label>

			<div className='form-icon position-relative'>
				<input
					type={type}
					id={name}
					onChange={onChange}
					value={value}
					required
					data-error={error}
					className='form-control '
					placeholder={placeholder}
					name={name}
				/>
				{error && <div className='help-block with-errors'></div>}
			</div>
    </div>*/
}
