import React, { useState } from 'react';
import FeaturedPlatform from '../homepage_components/platforms/featuredPlatform';
import { Helmet } from 'react-helmet';

const DownloadPage = () => {
  const [topThreeFeatures, setTopThreeFeatures] = useState([
    {
      order: 1,
      title: 'SavingPalz Web Portal',
      platformName: 'Web Portal',
      isWeb: true,
      isIOS: false,
      isAndroid: false,
      link: 'https://app.savingpalz.com/signup',
      message:
        'The full-featured web portal has all the features and capabilities of SavingPalz and is accessible on desktop, mobile, or whatever platform you choose to manage your finances. Available on any modern browser of your choosing.',
      icon: 'fa fa-thumbs-up',
      media: {
        mediaType: 'image',
        url: '',
        alt: 'Easy to Use',
        height: '25%',
        width: '25%',
      },
      iPadImg:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/GurenterMarketing%2F17E69E79-E433-4803-A1E2-7A7E4190B3EE%2FGurenterPromoImg4409?alt=media&token=e7ef6f09-c81c-4809-9384-8f770d1ce0ec',
      subTextDetail: {
        subName: 'Multi-Platform',
        subMessage: 'Your data is where you are.',
        icon: 'flaticon-smartphone-1',
      },
    },
    {
      order: 2,
      title: 'iOS and iPadOS App',
      platformName: 'iOS App',
      isWeb: false,
      isIOS: true,
      isAndroid: false,
      link: 'https://apps.apple.com/us/app/savingpalz-expense-tracker/id6464551318',
      message:
        'Access SavingPalz from your iPhone, iPad, or Apple Silicon-based Mac systems. Get it today on the Apple App Store.',
      icon: 'fa fa-thumbs-up',
      media: {
        mediaType: 'image',
        url: 'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FMobile%20App%2FDashboardLightMode.png?alt=media&token=662eb87a-37e9-4de5-8a08-e45eac6294de',
        alt: 'Easy to Use',
        height: '25%',
        width: '25%',
      },
      iPadImg:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FMobile%20App%2FRocketSim_Screenshot_iPad_Pro_(12.9-inch)_(6th_generation)_12.9_2024-05-14_12.23.17.jpeg?alt=media&token=af16c664-205d-456e-bf35-bb1f0b4dffe7',
      subTextDetail: {
        subName: 'Multi-Platform',
        subMessage: 'Your data is where you are.',
        icon: 'flaticon-smartphone-1',
      },
    },
  ]);

  const [sectionTitleDetail, setSectionTitleDetail] = useState({
    title: 'Download SavingPalz',
    message:
      'Use SavingPalz on the web, iOS, and iPadOS. Your data is where you are. Android is coming soon; note that our web portal is mobile-friendly.',
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz - Download</title>
        <meta
          name='description'
          content='Download SavingPalz on the web, iOS, and iPadOS. Your data is where you are. Android is coming soon; note that our web portal is mobile-friendly.'
        />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://savingpalz.com/download' />
        <meta property='og:title' content='SavingPalz - Download' />
        <meta
          property='og:description'
          content='Download SavingPalz on the web, iOS, and iPadOS. Your data is where you are. Android is coming soon; note that our web portal is mobile-friendly.'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Logo%2FSavingPalzLogoTransparent.png?alt=media&token=90e1e73a-4c37-4b9a-a4ff-b6a17781d78d'
        />

        {/* Twitter */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://savingpalz.com/download'
        />
        <meta property='twitter:title' content='SavingPalz - Download' />
        <meta
          property='twitter:description'
          content='Download SavingPalz on the web, iOS, and iPadOS. Your data is where you are. Android is coming soon; note that our web portal is mobile-friendly.'
        />
        <meta
          property='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Logo%2FSavingPalzLogoTransparent.png?alt=media&token=90e1e73a-4c37-4b9a-a4ff-b6a17781d78d'
        />
      </Helmet>

      <div className='container'>
        {/* Need a header here first*/}
        {/* SECTION TITLE */}
        <div className='row justify-content-center'>
          <div className='col-lg-10 col-xl-8'>
            <div className='section-title title-01 mb-80'>
              {/* Title */}
              <h2 className='h2-md'>{sectionTitleDetail.title}</h2>

              {/* Text */}
              <p className='p-xl'>{sectionTitleDetail.message}</p>
            </div>
          </div>
        </div>

        {topThreeFeatures
          .sort((a, b) => a.order - b.order)
          .map((feature) => (
            <div className='pt-3'>
              <FeaturedPlatform headerDetail={feature} />
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default DownloadPage;
