import React, { useState } from 'react';
import img1 from '../../../../styletheme/public/images/img-01.png';
import icoBg from '../../../../styletheme/public/images/ico-bkg.png';
import from from '../../../../styletheme/public/css/homepage/homePageFeatureOne.css';
import PortalLink from '../../../common/reusuable/button/portalLink';
import ClickableImage from '../../../common/reusuable/images/clickableImage';
import appStoreImg from '../.././../../styletheme/public/images/appstore.png';
import googlePlayImg from '../.././../../styletheme/public/images/googleplay.png';
import ClickableImageWithChild from '../../../common/reusuable/images/clickableImageWithChild';

const FeaturedPlatform = ({ headerDetail }) => {
  return (
    <React.Fragment>
      {/* BOTTOM ROW */}
      <div className='bottom-row'>
        <div className='row d-flex align-items-center'>
          {/* IMAGE BLOCK */}
          <div className='col-md-7 col-lg-6'>
            {/*Test */}
            {headerDetail && headerDetail.isWeb ? (
              <ClickableImage imageUrl={headerDetail.iPadImg} imgAlt='image' />
            ) : (
              // featuredPlatform.jsx

              <ClickableImageWithChild
                imageUrl={headerDetail.iPadImg}
                imgAlt='image'
              >
                <div className='iphone-image'>
                  <img
                    className='img-fluid'
                    style={{
                      width: `${headerDetail.media.width}`,
                      height: `${headerDetail.media.height}`,
                    }}
                    src={headerDetail.media?.url}
                    alt='video-preview'
                  />
                </div>
              </ClickableImageWithChild>
            )}

            {/* Test*/}
          </div>
          {/* TEXT BLOCK */}
          <div className='col-md-5 col-lg-6'>
            <div className='txt-block right-column wow fadeInLeft'>
              {/* Section ID */}
              <span className='section-id rounded-id bg-tra-purple purple-color txt-upcase'>
                {headerDetail.platformName}
              </span>

              {/* Title */}
              <h2 className='h2-xs'>{headerDetail.title}</h2>

              {/* Text */}
              <p className='p-lg'>{headerDetail.message}</p>

              {/* CONTENT BOX #1 */}

              {headerDetail && headerDetail.isWeb ? (
                <PortalLink portalUrl={headerDetail.link} />
              ) : (
                <>
                  {headerDetail && headerDetail.isIOS ? (
                    <a
                      href={headerDetail.link}
                      target='_blank'
                      className='store'
                    >
                      <img
                        className='appstore'
                        src={appStoreImg}
                        alt='appstore-badge'
                      />
                    </a>
                  ) : (
                    <a href='#' className='store' hidden>
                      <img
                        className='googleplay'
                        src={googlePlayImg}
                        alt='googleplay-badge'
                      />
                    </a>
                  )}
                </>
              )}
            </div>
          </div>{' '}
          {/* END TEXT BLOCK */}
        </div>
      </div>{' '}
      {/* END BOTTOM ROW */}
    </React.Fragment>
  );
};

export default FeaturedPlatform;
