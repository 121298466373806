import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';

function ClickableImageWithChild({
  imageUrl,
  altText,
  height,
  width,
  children,
}) {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  const handleClose = () => {
    setIsImageOpen(false);
  };

  return (
    <>
      <div
        className='img-fluid'
        style={{
          backgroundImage: `url("${imageUrl}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
          zIndex: 10000,
        }}
        onClick={handleImageClick}
      >
        {children}
      </div>

      <Modal show={isImageOpen} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{altText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={imageUrl}
            alt={altText}
            style={{
              maxHeight: height, // Set specific height and width when in full screen
              maxWidth: width,
            }}
            fluid
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClickableImageWithChild;
