import { link } from 'joi';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const PressKitPage = () => {
  const [contactEmail, setContactEmail] = useState('support@gurenter.com');
  const [presskitUrl, setPressKitUrl] = useState(
    'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FPressKit%2FSavingPalz-Press-Kit.zip?alt=media&token=80189819-a88b-41ac-be70-20fe2a0cefb7'
  );
  const [aboutSavingPalz, setAboutSavingPalz] = useState({
    title: 'About SavingPalz',
    content:
      'SavingPalz is a budgeting and expense-tracking app that helps you manage your finances. We also allow you to collaborate with others, and you control what you want to share with that Pal. It is found on web and ios devices, Android is coming soon.',
    sectionTitle: 'Main Features of SavingPalz',
    mainFeatures: [
      'Budget Management',
      'Expense Tracking',
      'Collaboration with others  through what we call Palz',
      'Budget Goal Setting and Management',
    ],
    historyDetailAboutSavingPalz: {
      title: 'History of SavingPalz',
      content: `SavingPalz was founded in June 2023, and one of SavingPalz's co-founders, Keijaoh, needed to create a budget that could be managed on the go. When he got married in 2022, he needed a way to merge his budget with his wife Marlee. He also wanted to be able to share an overview of their budget with his father and co-founder, Dr. Arnett Campbell, in a financial advisor role. Keijaoh felt that there needed to be a complete solution that had not existed to meet the core problems around collaborative budgeting and one where the Pal has the option to link at any time or decide to restrict or unlink that connection at any time.`,
    },
  });

  const [pricingDetail, setPricingDetail] = useState({
    title: 'Simple Pricing Structure',
    message:
      'Our pricing is easy to understand. We offer a 7-day free trial, after which you can continue with our free plan or upgrade to our pro plan.',
    link: '/pricing',
  });

  const [frequentlyAskedQuestion, setFrequentlyAskedQuestion] = useState({
    title: 'Frequently Asked Questions',
    content:
      'Our FAQ has everything you need to know before getting started. If you have any other questions, please feel free to contact us.',
    link: '/faq',
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz - Press Kit</title>
        <meta
          name='description'
          content='Download the SavingPalz Press Kit. Learn more about SavingPalz and its features.'
        />
        <meta
          name='keywords'
          content='press kit, press, press release, press kit download'
        />
        <meta property='og:title' content='SavingPalz - Press Kit' />
        <meta
          property='og:description'
          content='Download the SavingPalz Press Kit. Learn more about SavingPalz and its features.'
        />
      </Helmet>
      {/* DOWNLOAD-1
			============================================= */}
      <section
        id='download-1'
        className='bg-snow wide-20 download-section division'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10'>
              {/* VERSION RELEASE */}
              <div className='version-release'>
                {/* Release Data */}
                <div className='release-data'>
                  <h4 className='h4-xs'>SavingPalz Press kit</h4>
                  <b>Contact:</b>{' '}
                  <a
                    href={`
                mailto:${contactEmail}?subject=Press Kit Request&body=Hello, I would like to request information about SavingPalz. Thank you.
                `}
                  >
                    {contactEmail}
                  </a>
                  {/* Version Data */}
                  <div className='rel'>
                    <a
                      href={presskitUrl}
                      download='example.zip'
                      className='btn btn-skyblue tra-grey-hover ico-15 ico-left release-download'
                    >
                      <span className='flaticon-down-arrow'></span> Download Our
                      Press Kit
                    </a>
                  </div>
                </div>

                {/* Release Highlights */}
                <div className='release-highlights'>
                  {/*About SavingPalz */}
                  <h5 className='h4-xs'>{aboutSavingPalz.title}</h5>
                  <p>{aboutSavingPalz.content}</p>

                  {/* Main Features */}
                  <h5 className='h4-xs'>{aboutSavingPalz.sectionTitle}</h5>
                  <ul className='simple-list'>
                    {aboutSavingPalz.mainFeatures.map((feature, index) => (
                      <li key={index} className='list-item'>
                        <p className='p-lg'>{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className='release-highlights'>
                  {/*About SavingPalz */}
                  <h5 className='h4-xs'>{aboutSavingPalz.title}</h5>
                  <p>{aboutSavingPalz.historyDetailAboutSavingPalz.content}</p>
                </div>
              </div>{' '}
              {/* END VERSION RELEASE */}
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END DOWNLOAD-1 */}
      {/* DIVIDER LINE */}
      <hr className='divider' />
      {/* CALL TO ACTION-8
			============================================= */}
      <section id='cta-8' className='bg-snow wide-100 cta-section division'>
        <div className='container'>
          <div className='cta-8-wrapper pc-25'>
            <div className='row row-cols-1 row-cols-md-2'>
              {/* BOX #1 */}
              <div className='col'>
                <a href={pricingDetail.link}>
                  <div className='cta-box cta-top-box bg-white wow fadeInUp'>
                    {/* Icon */}
                    <div className='cta-ico'>
                      <div className='ico-60'>
                        <span className='flaticon-wallet'></span>
                      </div>
                    </div>

                    {/* Text */}
                    <div className='cta-txt'>
                      {/* Title */}
                      <h5 className='h5-md'>{pricingDetail.title}</h5>

                      {/* Text */}
                      <p className='p-lg'>{pricingDetail.message}</p>
                    </div>
                  </div>
                </a>
              </div>{' '}
              {/* END BOX #1 */}
              {/* BOX #2 */}
              <div className='col'>
                <a href={frequentlyAskedQuestion.link}>
                  <div className='cta-box bg-white wow fadeInUp'>
                    {/* Icon */}
                    <div className='cta-ico'>
                      <div className='ico-60'>
                        <span className='flaticon-help'></span>
                      </div>
                    </div>

                    {/* Text */}
                    <div className='cta-txt'>
                      {/* Title */}
                      <h5 className='h5-md'>{frequentlyAskedQuestion.title}</h5>

                      {/* Text */}
                      <p className='p-lg'>{frequentlyAskedQuestion.content}</p>
                    </div>
                  </div>
                </a>
              </div>{' '}
              {/* END BOX #2 */}
            </div>{' '}
            {/* End cta-8-wrapper */}
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END CALL TO ACTION-8 */}
    </React.Fragment>
  );
};

export default PressKitPage;
