import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';

import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fetchBlogByTitle,
  getLatestBlogsAndExclude,
} from '../../../../services/general/blogs/blogsService';
import NewsletterForm from '../../../common/reusuable/newletter/newsletterForm';
import LoadingView from '../../../common/reusuable/loading/loadingView';

const BlogDetail = () => {
  const history = useNavigate();
  const { blogTitle } = useParams();
  const [isLoading, setIsLoading] = useState();

  const [socialMediaLink, setSocialMediaLink] = useState({
    facebookSocialMediaLink:
      'https://www.facebook.com/p/SavingPalz-61550599034370/',
    linkedInSocialMediaLink: 'https://www.linkedin.com/company/savingpalz',
    youtubeSocialMediaLink: 'https://www.youtube.com/@SavingPalz',
  });

  //TODO create the blogs service and call it here
  const [Blog, setBlog] = useState({
    categoryName: '',
    blogId: '',
    blogTitle: '',
    blogDateTime: '',
    blogOverview: '',
    blogPromoImg: '', //web optimised
    blogSections: [
      {
        //todo soon
      },
    ],
    numberOfComments: 0,
  });

  //use to populate 3 recent blogs
  const [Blogs, setBlogs] = useState([
    {
      categoryName: '',
      blogId: '',
      blogTitle: '',
      blogDateTime: '',
      blogOverview: '',
      blogPromoImg: '', //web optimised
      blogSections: [
        {
          //todo soon
        },
      ],
      numberOfComments: 0,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getBlogDetail();
  }, []);

  const getBlogDetail = async () => {
    if (blogTitle !== undefined) {
      setIsLoading(true);
      //console.log("Search by " + blogTitle);
      const { data: blog } = await fetchBlogByTitle(blogTitle);
      //console.log(blog);
      //console.log("Title: " + blog.blogId);
      if (blog !== undefined && blog.length !== 0) {
        //console.log('Blog Detail');
        //console.log(blog);

        setBlog(blog);
        setIsLoading(false);
        const limit = 3;

        const { data: Blogs } = await getLatestBlogsAndExclude(
          blog.blogId,
          limit
        );

        if (Blogs !== undefined) setBlogs(Blogs);

        setIsLoading(false);
      } else
        toast.warning(
          'No Blog Item Found, Please select Blog To see all available.'
        );

      setIsLoading(false);
    } else if (blogTitle === undefined) {
      toast.warning(
        'No Blog Item Found, Please select Blog To see all available.'
      );

      setIsLoading(false);
    }
  };

  const updateBlogsUI = async (value) => {
    setIsLoading(true);
    history(`/blogs/${value.categoryName}`, {
      replace: true,
    });
    window.scrollTo(0, 0);
  };

  const updateUI = async (value) => {
    setIsLoading(true);

    window.scrollTo(0, 0);

    if (value !== undefined && value !== '') {
      history(`/blog/${value.blogTitle}`, {
        replace: true,
      });

      const { data: blog } = await fetchBlogByTitle(value.blogTitle);

      if (blog !== undefined && blog.length !== 0) {
        setBlog(blog);
        setIsLoading(false);
        const { data: Blogs } = await getLatestBlogsAndExclude(blog.blogId, 3);
        if (Blogs !== undefined) setBlogs(Blogs);
      } else
        toast.warning(
          'No Blog Item Found, Please select Blog To see all available.'
        );
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* SEO Metadata */}
      <Helmet>
        <title>SavingPalz: {Blog.blogTitle}</title>
        <meta name='description' content={Blog.blogOverview} />
        <meta
          name='keywords'
          content={`${Blog.categoryName}, ${
            Blog.blogTags?.map((tag) => tag.tagLink).join(', ') || ''
          }`}
        />
        <meta name='author' content={Blog.authorProfile?.authorName} />
        <meta name='robots' content='index, follow' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta
          property='og:title'
          content={Blog.blogTitle}
          data-rh='true'
          data-react-helmet='true'
        />
        <meta
          property='og:description'
          content={Blog.blogOverview}
          data-rh='true'
          data-react-helmet='true'
        />

        <meta
          property='og:image'
          content={Blog.blogPromoImg}
          data-rh='true'
          data-react-helmet='true'
        />

        <meta property='og:url' content={window.location.href} />
        <meta property='og:type' content='website' />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={window.location.href} />
        <meta
          property='twitter:title'
          content={`SavingPalz: ${Blog.blogTitle}`}
        />
        <meta property='twitter:description' content={Blog.blogOverview} />
        <meta property='twitter:image' content={Blog.blogPromoImg} />
      </Helmet>
      {/* PRELOADER SPINNER
============================================= */}
      {isLoading && <LoadingView />}
      {/* SINGLE POST
============================================= */}
      <section
        id='single-post'
        className='wide-100 single-post-section division'
      >
        <div className='container'>
          {/* SINGLE POST CONTENT */}
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='single-post-wrapper'>
                {/* SINGLE POST TITLE */}
                <div className='single-post-title'>
                  {/* CATEGORY */}
                  <p className='p-sm post-tag txt-500 txt-upcase'>
                    {Blog.categoryName}
                  </p>
                  {/* TITLE */}
                  <h2 className='h2-md'>{Blog.blogTitle}</h2>
                  {/* POST DATA */}
                  <div className='post-data clearfix'>
                    {/* Author Avatar */}
                    <div className='post-author-avatar'>
                      <img
                        src={Blog.authorProfile?.authorProfileImg}
                        alt='author-avatar'
                        style={{ height: '60px', width: '60px' }}
                      />
                    </div>

                    {/* Author Data */}
                    <div className='post-author'>
                      <h6 className='h6-xl'>
                        {Blog.authorProfile?.authorName}
                      </h6>
                      <p className='p-md'>
                        Posted on{' '}
                        <span>
                          {new Date(Blog.recordCreatedDate).getDate()}{' '}
                          {new Date(Blog.recordCreatedDate).toLocaleString(
                            'default',
                            {
                              month: 'long',
                            }
                          )}{' '}
                          {new Date(Blog.recordCreatedDate).getFullYear()}
                        </span>
                      </p>
                    </div>
                  </div>{' '}
                  {/* END POST DATA */}
                </div>{' '}
                {/* END SINGLE POST TITLE */}
                {/* Youtube video here if found start */}
                {Blog.youTubeVideoCode && Blog.youTubeVideoCode !== 'N/A' && (
                  <div className='video-preview'>
                    {/* Play Icon */}
                    <Iframe
                      url={
                        'https://www.youtube.com/embed/' + Blog.youTubeVideoCode
                      }
                      width='90%'
                      height='585px'
                      id='youTubeVideoCode'
                      display='initial'
                      frameborder='0'
                      allowfullscreen
                      position='relative'
                    />
                  </div>
                )}
                {/* Youtube video here if found end */}
                {/* BLOG POST TEXT */}
                <div className='single-post-txt pt-3'>
                  {/* Text */}
                  <p className='p-lg'>{Blog.blogOverview}</p>
                </div>{' '}
                {/* END BLOG POST TEXT */}
                {/* BLOG POST INNER IMAGE */}
                {!Blog.youTubeVideoCode ||
                  Blog.youTubeVideoCode === '' ||
                  (Blog.youTubeVideoCode === 'N/A' && (
                    <div className='post-inner-img'>
                      <img
                        className='img-fluid'
                        src={Blog.blogPromoImg}
                        alt={Blog.blogTitle}
                      />
                    </div>
                  ))}
                {/* BLOG POST TEXT SECTIONS*/}
                <div className='single-post-txt pt-3'>
                  {/* Title */}
                  {/* Loop over section of list */}
                  {Blog.blogSections.map((section, index) => {
                    if (section.sectionType === 'text') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>
                            {/* Text */}
                            <p className='p-lg'>{section.sectionText}</p>
                            {/* Digit List */}
                            <ol className='digit-list'>
                              {section.sectionList &&
                                section.sectionList.length > 0 &&
                                section.sectionList.map((listItem, index) => (
                                  <div key={index}>
                                    <li>
                                      <p className='p-lg'>
                                        {listItem.listText}
                                      </p>
                                    </li>
                                  </div>
                                ))}
                            </ol>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'Image') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>
                            {/* Image */}

                            {section.sectionImg &&
                              section.sectionImg !== '' && (
                                <img
                                  className='img-fluid'
                                  src={section.sectionImg}
                                  alt={section.sectionImgAlt}
                                />
                              )}
                            {/* Image Caption */}
                            <p>{section.sectionImgCaption}</p>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'Paragraph') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>

                            {/* Image */}

                            {section.sectionImg &&
                              section.sectionImg !== '' && (
                                <img
                                  className='img-fluid'
                                  src={section.sectionImg}
                                  alt={section.sectionImgAlt}
                                />
                              )}

                            {/* Text */}
                            <p className='p-lg'>{section.sectionText}</p>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'videoAndContent') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>
                            {/* Video */}
                            <div className='video-preview'>
                              {/* Play Icon */}
                              <Iframe
                                url={
                                  'https://www.youtube.com/embed/' +
                                  section.youTubeVideoCode
                                }
                                width='90%'
                                height='585px'
                                id='youTubeVideoCode'
                                display='initial'
                                frameborder='0'
                                allowfullscreen
                                position='relative'
                              />
                            </div>
                            {/* Text */}
                            <p className='p-lg'>{section.sectionText}</p>
                          </section>
                        </React.Fragment>
                      );
                    } else if (section.sectionType == 'quote') {
                      return (
                        <React.Fragment>
                          {/* QUOTE */}
                          <div className='quote mt-30 mb-35'>
                            {/* Quote Text */}
                            <p className='p-xl'>{section.sectionText}</p>
                          </div>
                        </React.Fragment>
                      );
                    } else if (section.sectionType === 'textAndMultiImg') {
                      return (
                        <React.Fragment>
                          <section key={index}>
                            <h4 className='h4-lg'>{section.sectionTitle}</h4>

                            <div className='row'>
                              {section.sectionImgs &&
                                section.sectionImgs.length > 0 &&
                                section.sectionImgs.map((img, index) => (
                                  <div
                                    className='col-md-6 top-img blog-post-img'
                                    key={index}
                                  >
                                    <React.Fragment>
                                      {img.imgUrl && img.imgUrl !== '' && (
                                        <>
                                          <img
                                            className='img-fluid'
                                            src={img.imgUrl}
                                            alt={img.imgCaption}
                                            style={{
                                              height: '585px',
                                              width: '90%',
                                              objectFit: 'contain',
                                            }}
                                          />
                                          <p>{img.imgCaption}</p>
                                        </>
                                      )}
                                      {section.sectionImg &&
                                        section.sectionImg !== '' && (
                                          <img
                                            className='img-fluid'
                                            src={section.sectionImg}
                                            alt={section.sectionImgAlt}
                                            style={{
                                              height: '585px',
                                              width: '90%',
                                              objectFit: 'contain',
                                            }}
                                          />
                                        )}
                                      <p>{img.imgCaption}</p>
                                    </React.Fragment>
                                  </div>
                                ))}
                            </div>

                            {/* Text */}
                            <p className='p-lg'>{section.sectionText}</p>
                          </section>
                        </React.Fragment>
                      );
                    } else {
                      return <div key={index}></div>;
                    }
                  })}
                </div>{' '}
                {/* END BLOG POST TEXT */}
                {/* SINGLE POST SHARE LINKS */}
                <div className='row post-share-links d-flex align-items-center'>
                  {/* POST TAGS */}
                  {Blog.blogTags && Blog.blogTags.length > 0 && (
                    <div className='col-md-9 col-xl-8 post-tags-list mb-5'>
                      {' '}
                      {/* Increase bottom margin here */}
                      {Blog.blogTags.map((tag, index) => (
                        <span key={index} className='me-3 mb-3 d-inline-block'>
                          {' '}
                          {/* Add d-inline-block here */}
                          <Link to={tag.tagLink}>{tag.tagLink}</Link>
                        </span>
                      ))}
                    </div>
                  )}
                  {/* POST SHARE ICONS */}
                  <div className='col-md-3 col-xl-4 post-share-list text-end'>
                    <ul className='share-social-icons ico-25 text-center clearfix'>
                      <li>
                        <a
                          href={socialMediaLink.linkedInSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-linkedin'></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={socialMediaLink.facebookSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-facebook'></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={socialMediaLink.youtubeSocialMediaLink}
                          target='_blank'
                          className='share-ico'
                        >
                          <span className='flaticon-youtube'></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* END SINGLE POST SHARE */}
              </div>
            </div>
          </div>{' '}
          {/* END SINGLE POST CONTENT */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END SINGLE POST */}
      {/* ABOUT POST AUTHOR
			============================================= */}
      <div className='about-post-author bg-snow division'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='author-senoff'>
                {/* Avatar */}

                <img
                  src={Blog.authorProfile?.authorProfileImg}
                  alt='author-avatar'
                  style={{ height: '120px', width: '120px' }}
                />

                {/* Text */}
                <div className='author-senoff-txt'>
                  {/* Title */}
                  <h5 className='h5-xs'>Published by</h5>
                  <h5 className='h5-md'>{Blog.authorProfile?.authorName}</h5>
                  <p className='p-md'>{Blog.authorProfile?.authorBio}</p>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </div>{' '}
      {/* END ABOUT POST AUTHOR */}
      {/* POST COMMENTS (Future)
			============================================= */}
      {/* END POST COMMENTS */}
      {/* BLOG-1 NEXT
			============================================= */}
      <section
        id='blog-1'
        className='bg-whitesmoke-gradient wide-60 blog-section division'
      >
        <div className='container'>
          {/* SECTION TITLE */}
          {Blogs.length > 0 && Blogs[0].blogTitle != '' && (
            <div className='row justify-content-center'>
              <div className='col-lg-10 col-xl-8'>
                <div className='section-title title-01 mb-70'>
                  <h2 className='h2-md'>Keep Reading...</h2>
                </div>
              </div>
            </div>
          )}
          {/* BLOG POSTS */}
          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
            {Blogs.map((blog, index) => (
              <React.Fragment key={index}>
                {/* BLOG POST #1 */}
                <div className='col'>
                  <div id='bp-1-1' className='blog-1-post mb-40 wow fadeInUp'>
                    {/* BLOG POST IMAGE */}
                    <div className='blog-post-img'>
                      <div className='hover-overlay'>
                        <Link
                          to={`/blog/${blog.blogTitle}`}
                          onClick={() => updateUI(blog)}
                        >
                          <img
                            className='img-fluid'
                            src={blog.blogPromoImg}
                            alt={blog.blogTitle}
                            style={{
                              height: '364px',
                              width: '416px',
                              objectFit: 'contain',
                            }}
                          />
                        </Link>
                        <div className='item-overlay'></div>
                      </div>
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className='blog-post-txt'>
                      {/* Post Tag */}
                      <p className='p-md post-tag'>
                        {blog.categoryName} &ensp;|&ensp;
                        <span>
                          {new Date(blog.recordCreatedDate).getDate()}{' '}
                          {new Date(blog.recordCreatedDate).toLocaleString(
                            'default',
                            {
                              month: 'long',
                            }
                          )}{' '}
                          {new Date(blog.recordCreatedDate).getFullYear()}
                        </span>
                      </p>

                      {/* Post Link */}
                      <h5 className='h5-md'>
                        <Link
                          to={`/blog/${blog.blogTitle}`}
                          onClick={() => updateUI(blog)}
                        >
                          {blog.blogTitle}
                        </Link>
                      </h5>

                      {/* Text */}
                      <p className='p-lg'>
                        {blog.blogOverview.length > 200
                          ? blog.blogOverview.substring(0, 200) + '...'
                          : blog.blogOverview}
                      </p>
                    </div>{' '}
                    {/* END BLOG POST TEXT */}
                  </div>
                </div>{' '}
                {/* END  BLOG POST #1 */}
              </React.Fragment>
            ))}
          </div>{' '}
          {/* END BLOG POSTS */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END BLOG-1 */}
      {/* NEWSLETTER-1
			============================================= */}
      <NewsletterForm />
      {/* END NEWSLETTER-1 */}
    </React.Fragment>
  );
};

export default BlogDetail;
