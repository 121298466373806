import React, { Component } from 'react';
import uuid from 'react-uuid';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import Form from '../../common/reusuable/form';
import { createContactMessage } from '../../../services/contact/contactService';
import Input from '../../common/reusuable/input';
import TextArea from '../../common/reusuable/textarea';
import { Helmet } from 'react-helmet';

class ContactUs extends Form {
  state = {
    data: {
      //contactId: "",
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      //date: "",
    },
    errors: {},
    baseState: {},
  };

  schema = {
    //contactId: Joi.string().required().label("Contact ID"),
    firstName: Joi.string().required().label('First Name'),
    lastName: Joi.string().required().label('Last Name'),
    email: Joi.string().required().email().label('Email'),
    message: Joi.string().required().label('Message'),
    //date: Joi.string().required().label("Date"),
  };

  async componentDidMount() {
    //set at the top of the screen
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);

    // preserve the initial state in a new object
    this.baseState = this.state; ///>>>>>>>>> note this one.
  }
  resetForm = () => {
    this.setState(this.baseState); ///>>>>>>>>> note this one.
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      //call the api method

      const firstName = data.firstName;
      const lastName = data.lastName;
      const email = data.email;
      const message = data.message;
      const date = Date().toString(); //convert to string

      //Need to send this to the api and get the token
      const contactRequest = {
        contactId: uuid(), //set a random ID
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message,
        date: date,
      };
      try {
        await createContactMessage(contactRequest);
        //Thank user
        toast.success('Contact Request successfully received.');
        //reset the form and initial state
        this.resetForm();
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.videoTitle = ex.response.data;
          toast.error(ex.response.data);
          //Update the UI
          this.setState({ errors });
        }
      }
      //force a reload of the page
      //GO back to the video management
      //window.location = state ? state.from.pathname : "/videoperfmmanagement";
      //npm i react-uuid
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.videoTitle = ex.response.data;

        toast.error(ex.response.data);
        //Update the UI
        this.setState({ errors });
      }
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>SavingPalz - Contact Us</title>
          <meta
            name='description'
            content="Have a question? Need help? Don't hesitate, drop us a line. We will get back to you as soon as we can."
          />

          <meta
            name='keywords'
            content='contact us, contact, contact form, contact form submission, contact form submission success'
          />

          <meta property='og:title' content='SavingPalz - Contact Us' />
          <meta
            property='og:description'
            content="Have a question? Need help? Don't hesitate, drop us a line. We will get back to you as soon as we can."
          />
        </Helmet>
        {/* CONTACTS-2
			============================================= */}
        <section id='contacts-2' className='bg-snow contacts-section division'>
          <div className='container'>
            {/* SECTION TITLE */}
            <div className='row justify-content-center'>
              <div className='col-md-10 col-lg-8'>
                <div className='section-title title-02 mb-80'>
                  {/* Title */}
                  <h2 className='h2-xs'>
                    Have a question? Need help? Don't hesitate, drop us a line
                  </h2>

                  {/* Text */}
                  <p className='p-xl'>
                    We will get back to you as soon as we can.
                  </p>
                </div>
              </div>
            </div>
            {/* CONTACT FORM */}
            <div className='row justify-content-center'>
              <div className='col-lg-12 col-xl-12'>
                <div className='form-holder'>
                  <form
                    onSubmit={this.handleSubmit}
                    name='contactform'
                    className='row contact-form'
                  >
                    <div className='col-md-12'>
                      <div className='row'>
                        <Input
                          name='firstName'
                          type='text'
                          className='form-control'
                          error={errors.firstName}
                          value={data.firstName}
                          placeholder='First Name'
                          label='First Name'
                          onChange={this.handleChange}
                        />

                        <Input
                          name='lastName'
                          type='text'
                          className='form-control'
                          error={errors.lastName}
                          value={data.lastName}
                          placeholder='Last Name'
                          label='Last Name'
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <Input
                        name='email'
                        type='email'
                        error={errors.email}
                        value={data.email}
                        className='form-control'
                        placeholder='Email Address'
                        label='Email Address'
                        onChange={this.handleChange}
                      />
                    </div>

                    <TextArea
                      name='message'
                      type='text'
                      className='form-control'
                      error={errors.message}
                      value={data.message}
                      placeholder='Write your message here.'
                      label='Message'
                      onChange={this.handleChange}
                      rows={6}
                    />

                    {this.renderButton('Send Message', 'btn btn-primary')}
                  </form>
                </div>
              </div>
            </div>
            {/* END CONTACT FORM */}
          </div>{' '}
          {/* End container */}
        </section>{' '}
        {/* END CONTACTS-2 */}
        {/* DIVIDER LINE */}
        <hr className='divider' hidden />
        {/* CALL TO ACTION-8
			============================================= */}
        <section
          id='cta-8'
          className='bg-snow wide-100 cta-section division'
          hidden
        >
          <div className='container'>
            <div className='cta-8-wrapper pc-25'>
              <div className='row row-cols-1 row-cols-md-2'>
                {/* BOX #1 */}
                <div className='col'>
                  <a href='pricing.html'>
                    <div className='cta-box cta-top-box bg-white wow fadeInUp'>
                      {/* Icon */}
                      <div className='cta-ico'>
                        <div className='ico-60'>
                          <span className='flaticon-wallet'></span>
                        </div>
                      </div>

                      {/* Text */}
                      <div className='cta-txt'>
                        {/* Title */}
                        <h5 className='h5-md'>View Pricing</h5>

                        {/* Text */}
                        <p className='p-lg'>
                          Porta semper lacus cursus feugiat primis ultrice
                          ligula risus at auctor tempus
                        </p>
                      </div>
                    </div>
                  </a>
                </div>{' '}
                {/* END BOX #1 */}
                {/* BOX #2 */}
                <div className='col'>
                  <a href='faqs.html'>
                    <div className='cta-box bg-white wow fadeInUp'>
                      {/* Icon */}
                      <div className='cta-ico'>
                        <div className='ico-60'>
                          <span className='flaticon-help'></span>
                        </div>
                      </div>

                      {/* Text */}
                      <div className='cta-txt'>
                        {/* Title */}
                        <h5 className='h5-md'>Read the FAQs</h5>

                        {/* Text */}
                        <p className='p-lg'>
                          Porta semper lacus cursus feugiat primis ultrice
                          ligula risus at auctor tempus
                        </p>
                      </div>
                    </div>
                  </a>
                </div>{' '}
                {/* END BOX #2 */}
              </div>{' '}
              {/* End cta-8-wrapper */}
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* End container */}
        </section>{' '}
        {/* END CALL TO ACTION-8 */}
      </React.Fragment>
    );
  }
}

export default ContactUs;
