import React, { useEffect, useState } from 'react';
import about_img_1 from '../../styletheme/public/images/about-1-img.jpg';
import about_img_2 from '../../styletheme/public/images/about-2-img.jpg';

import LoadingView from '../common/reusuable/loading/loadingView';
import ClickableImage from '../common/reusuable/images/clickableImage';

import { Helmet } from 'react-helmet';

const About = () => {
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about SavingPalz and its offerings',
  });

  const [loadingAnimation, setLoadingAnimation] = useState({
    isloading: false,
  });

  const [uniquenessMessage, setUniquenessMessage] = useState({
    title: 'What makes SavingPalz Different',
    message: `Struggling with finances? Let's tackle it together. SavingPalz is not only user-friendly and secure—it's the tool our founders actively use to manage their finances. Built from our personal challenges, we're committed to helping you succeed financially.`,
  });

  const [topThreeFeatures, setTopThreeFeatures] = useState([
    {
      title: 'Budget Management',
      order: 1,
      message:
        'SavingPalz helps you to breakdown your budget into categories and track your spending.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FBudget.png?alt=media&token=a09340fc-4521-422a-a219-7206bfb12817',
    },
    {
      title: 'Expense Management',
      order: 2,
      message:
        'SavingPalz helps you to track your expenses and see where your money is going.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FExpenses.png?alt=media&token=edb2f012-5b1e-41f2-8557-25452a3c7a71',
    },
    {
      title: 'Budget Goal Setting',
      order: 3,
      message:
        'SavingPalz helps you to set budget goals and track your progress towards them.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/savingspals-52bbe.appspot.com/o/Marketing%20Images%2FHome%20Page%2FBudgetGoals.png?alt=media&token=28bbe0e6-92b5-48f0-a2e4-5e6a233f0a83',
    },
  ]);

  const [ourTeam, setOurTeam] = useState({
    title: 'Meet the Team',
    message:
      'We are a small team passionate about budgeting and financing, and we pour that passion into SavingPalz to make your financial journey a little smoother.',
    teamMembers: [
      {
        firstName: 'Arnett',
        lastName: 'Campbell, Phd',
        role: 'Co-Founder and CEO',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FStaff%2FIMG_9762.png?alt=media&token=bc655810-6c19-4fd5-a43f-9f1968028b84',
      },
      {
        firstName: 'Keijaoh',
        lastName: 'Campbell, MBA',
        role: 'Co-Founder and CTO',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/authorProfilePictures%2FIMG_0037.JPG?alt=media&token=5564320c-10c0-4db6-929d-cccf3dbb76e9',
      },
      {
        firstName: 'Marlee',
        lastName: 'Collins',
        role: 'Marketing and Communications Specialist',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/PromotionalWebsite%2FStaff%2FIMG_7436.png?alt=media&token=5f7109dd-46fd-432d-983e-cad2021f03dc',
      },
    ],
  });

  const [aboutUsWelcomeMessage, setAboutUsWelcomeMessage] = useState({
    title: 'Welcome to SavingPalz',
    message:
      'SavingPalz is a budgeting and expense-tracking app that helps you manage your finances. We also allow you to collaborate with others, and you control what you want to share with that Pal.',
  });

  const [callToActionMessage, setCallToActionMessage] = useState({
    title: 'Ready to get started?',
    message:
      'Sign up today on our web portal and continue your financial journey with us!',
    buttonText: 'Sign Up',
    buttonLink: '/signup',
    appStoreLink: 'https://app.savingpalz.com/signup',
  });

  useEffect(() => {
    //start loading animation
    setLoadingAnimation({ isloading: true });
    window.scrollTo(0, 0);

    //after finish loading data from server
    //stop loading animation
    setLoadingAnimation({ isloading: false });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>SavingPalz - About Us</title>
        <meta
          name='description'
          content='Learn more about SavingPalz and its team. Find out what makes SavingPalz different and meet the team behind the app.'
        />
        <meta
          name='keywords'
          content='about us, savingpalz about us, savingpalz team, savingpalz team members'
        />
        <meta property='og:title' content='SavingPalz - About Us' />
        <meta
          property='og:description'
          content='Learn more about SavingPalz and its team. Find out what makes SavingPalz different and meet the team behind the app.'
        />
      </Helmet>
      {/* PRELOADER SPINNER
		============================================= */}
      {loadingAnimation.isloading && <LoadingView />}
      {/* ABOUT-2
			============================================= */}
      <section id='about-2' className='about-section'>
        <div className='bg-inner bg-lightgrey inner-page-hero division'>
          <div className='container'>
            {/* ABOUT-2 TITLE */}
            <div className='row justify-content-center'>
              <div className='col-md-10 col-lg-8'>
                <div className='about-2-title'>
                  {/* Title */}
                  <h2 className='h2-xl'>{aboutUsWelcomeMessage.title}</h2>

                  {/* Text */}
                  <p className='p-xl'>{aboutUsWelcomeMessage.message}</p>
                </div>
              </div>
            </div>{' '}
            {/* END ABOUT-2 TITLE */}
            {/* ABOUT-2 IMAGES */}
            <div className='about-2-images'>
              <div className='row row-cols-1 row-cols-md-2'>
                {/* IMAGES-1 */}
                <div className='col col-md-5'>
                  <img
                    className='img-fluid'
                    src={about_img_1}
                    alt='about-image'
                  />
                </div>

                {/* IMAGES-2 */}
                <div className='col col-md-7'>
                  <img
                    className='img-fluid'
                    src={about_img_2}
                    alt='about-image'
                  />
                </div>
              </div>{' '}
              {/* End row */}
            </div>{' '}
            {/* END ABOUT-2 IMAGES */}
          </div>{' '}
          {/* End container */}
        </div>{' '}
        {/* End bg-inner */}
      </section>{' '}
      {/* END ABOUT-2 */}
      {/* FEATURES-4
			============================================= */}
      {/* END FEATURES-4 */}
      {/* DIVIDER LINE */}
      <hr hidden className='divider' />
      {/* FEATURES-8
			============================================= */}
      <section
        id='features-8'
        className='pb-60 wide-50 features-section division'
      >
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-xl-8'>
              <div className='section-title title-01 mb-70'>
                {/* Title */}
                <h2 className='h2-md'>{uniquenessMessage.title}</h2>

                {/* Text */}
                <p className='p-xl'>{uniquenessMessage.message}</p>
              </div>
            </div>
          </div>
          {/* FEATURES-8 WRAPPER */}
          <div className='fbox-8-wrapper text-center'>
            <div className='row row-cols-1 row-cols-md-3'>
              {topThreeFeatures.map((reason, index) => {
                return (
                  <div className='col' key={index}>
                    <div className='fbox-8 mb-40 wow fadeInUp'>
                      {/* Image */}
                      <div className='fbox-img bg-whitesmoke-gradient'>
                        <ClickableImage
                          imageUrl={reason.imageUrl}
                          altText={reason.title}
                        />
                      </div>

                      {/* Title */}
                      <h5 className='h5-md'>{reason.title}</h5>

                      {/* Text */}
                      <p className='p-lg'>{reason.message}</p>
                    </div>
                  </div>
                );
              })}
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* END FEATURES-8 WRAPPER */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END FEATURES-8 */}
      {/* CONTENT-5
			============================================= */}
      {/* END CONTENT-5 */}
      {/* TEAM-1
			============================================= */}
      <section id='team-1' className=' team-section division'>
        <div className='container'>
          {/* SECTION TITLE */}
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-xl-8'>
              <div className='section-title title-01 mb-80'>
                {/* Title */}
                <h2 className='h2-md'>{ourTeam.title}</h2>

                {/* Text */}
                <p className='p-xl'>{ourTeam.message}</p>
              </div>
            </div>
          </div>
          {/* TEAM MEMBERS HOLDER */}
          <div className='team-members-wrapper text-center'>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4'>
              {ourTeam.teamMembers.map((member, index) => {
                return (
                  <div className='col'>
                    <div className='team-member wow fadeInUp'>
                      {/* Team Member Photo */}
                      <div className='team-member-photo'>
                        <img
                          className='img-fluid'
                          src={member.imageUrl}
                          alt='team-member-foto'
                          style={{ height: '306px', width: '306px' }}
                        />
                      </div>

                      {/* Team Member Data */}
                      <div className='team-member-data'>
                        <h5 className='h5-sm'>
                          {member.firstName} {member.lastName}
                        </h5>
                        <p className='p-lg'>{member.role}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>{' '}
            {/* End row */}
          </div>{' '}
          {/* TEAM MEMBERS HOLDER */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END TEAM-1 */}
      {/* TESTIMONIALS-1
			=============================================  In the future*/}
      {/* CALL TO ACTION-5
			============================================= */}
      <section id='cta-5' className='cta-section division'>
        <div className='container'>
          <div className='rel bg-04 cta-5-wrapper'>
            <div className='row justify-content-center'>
              {/* CALL TO ACTION TEXT */}
              <div className='col-lg-8'>
                <div className='cta-5-txt white-color text-center'>
                  {/* Title */}
                  <h2 className='h2-xs'>{callToActionMessage.title}</h2>

                  {/* Text */}
                  <p className='p-xl'>{callToActionMessage.message}</p>

                  {/* Button */}
                  <a
                    href={callToActionMessage.appStoreLink}
                    target='_blank'
                    className='btn btn-violet-red tra-white-hover'
                  >
                    Get Started Now
                  </a>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* End row */}
        </div>{' '}
        {/* End container */}
      </section>{' '}
      {/* END CALL TO ACTION-5 */}
    </React.Fragment>
  );
};

export default About;
