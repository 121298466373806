import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';

function ClickableImage({ imageUrl, altText, height, width }) {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  const handleClose = () => {
    setIsImageOpen(false);
  };

  return (
    <>
      <img
        className='img-fluid'
        src={imageUrl}
        alt={altText}
        onClick={handleImageClick}
        style={{ position: 'relative', zIndex: 10000 }} // Increased z-index
      />

      <div
        style={{
          position: 'relative',
          zIndex: 10000,
          backgroundColor: 'white',
          opacity: 0.5,
        }}
        onClick={handleImageClick}
      >
        <Image
          src={imageUrl}
          alt={altText}
          style={{
            height: '100%', // Make the image fill its container when not in full screen
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
      </div>

      <Modal show={isImageOpen} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{altText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={imageUrl}
            alt={altText}
            style={{
              maxHeight: height, // Set specific height and width when in full screen
              maxWidth: width,
            }}
            fluid
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClickableImage;
